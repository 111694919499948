import React from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import { get } from "lodash";

import {
  Alert as MuiAlert,
  Button as MuiButton,
  CircularProgress,
  Grid,
  Paper,
  Box,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "../../../utils/axios";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function CompanyForm({ company, onCompanyCreate, onCompanyUpdate }) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const updateCompany = async (param) => {
    const { values, setErrors, setStatus, setSubmitting } = param;
    try {
      const response = await axios.put(
        `/admin/companies/${company.id}`,
        values
      );
      const responseData = response.data;
      if (get(responseData, "status", false) === true) {
        setSubmitting(false);
        setStatus({ sent: true });
        onCompanyUpdate();
      } else {
        setStatus({ sent: false });
        const apiErrors = get(responseData, "message.messages.errors", []);
        if (apiErrors.length === 0) {
          apiErrors.push({ field: "Update", message: "Operation failed." });
        }
        setErrors({
          submit: apiErrors.map((e) => `[${e.field}] ${e.message}`),
        });
        setSubmitting(false);
      }
    } catch (error) {
      setSubmitting(false);
      setStatus({ sent: false });
      setErrors({ submit: error.message });
    }
  };
  const createCompany = async (param) => {
    const { values, resetForm, setErrors, setStatus, setSubmitting } = param;
    try {
      const response = await axios.post("/admin/companies", values);
      const responseData = response.data;
      if (get(responseData, "status", false) === true) {
        resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
        onCompanyCreate(responseData.data);
      } else {
        setStatus({ sent: false });
        setErrors({
          submit: get(responseData, "message.messages.errors", []).map(
            (e) => `[${e.field}] ${e.message}`
          ),
        });
        setSubmitting(false);
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    if (company) {
      updateCompany({
        values,
        setSubmitting,
        resetForm,
        setErrors,
        setStatus,
      });
    } else {
      createCompany({
        resetForm,
        setSubmitting,
        values,
        setErrors,
        setStatus,
      });
    }
  };
  const initialValues = {
    name: get(company, "name", ""),
  };
  return (
    <Paper elevation={16} sx={{ mt: 8 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                Company created successfully!
              </Alert>
            )}
            {status &&
              status.sent === false &&
              get(errors, "submit", []).map((e) => (
                <Alert severity="error" my={3}>
                  {e}
                </Alert>
              ))}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <TextField
                      name="name"
                      label="Comapny Name*"
                      value={values.name === null ? "" : values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    mt={3}
                  >
                    {company ? "Update" : "Save"}
                  </Button>
                </Grid>
              </form>
            )}
          </>
        )}
      </Formik>
    </Paper>
  );
}

export default CompanyForm;
