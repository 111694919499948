import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";

const NotesModal = ({
  open,
  handleClose,
  noteInput,
  isLoading,
  isError,
  selectedNote,
  handleSave,
  handleUpdateNote,
  setNoteInput,
  isNoteTextEmpty,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "5px",
          p: 6,
          width: { xs: "90%", sm: "70%", md: "50%", lg: "35%" },
        }}
      >
        <Stack spacing={5}>
          <Typography variant="h6" component="h2">
            {selectedNote ? "Update note" : "Add new note"}
          </Typography>
          <TextField
            variant="outlined"
            placeholder=""
            fullWidth
            multiline
            minRows={5}
            maxRows={10}
            value={noteInput}
            onChange={(event) => {
              setNoteInput(event.target.value);
            }}
          />
          {isLoading || isError ? (
            <LoadingButton loading variant="contained">
              {selectedNote ? `Update` : `Save`}
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              onClick={selectedNote ? handleUpdateNote : handleSave}
              disabled={isNoteTextEmpty()}
            >
              {selectedNote ? `Update` : `Save`}
            </Button>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default NotesModal;
