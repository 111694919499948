import React from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import { get } from "lodash";

import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  CircularProgress,
  Grid,
  Paper,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "../../../utils/axios";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function AdminForm({ admin, company, onAdminCreate, onAdminUpdate }) {
  let validationSchema = null;
  const validationObject = {
    name: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
  };
  if (admin) {
    validationSchema = Yup.object().shape(validationObject);
  } else {
    validationObject.password = Yup.string()
      .min(8, "Must be at least 8 characters")
      .max(255)
      .required("Required");
    validationSchema = Yup.object().shape(validationObject);
  }
  const updateAdmin = async (param) => {
    const { values, setErrors, setStatus, setSubmitting } = param;
    try {
      const response = await axios.put(
        `/admin/company/${company.id}/admin/${admin.id}`,
        values
      );
      const responseData = response.data;
      if (get(responseData, "status", false) === true) {
        setStatus({ sent: true });
        setSubmitting(false);
        onAdminUpdate();
      } else {
        setStatus({ sent: false });
        const apiErrors = get(responseData, "message.messages.errors", []);
        if (apiErrors.length === 0) {
          apiErrors.push({ field: "Update", message: "Operation failed." });
        }
        setErrors({
          submit: apiErrors.map((e) => `[${e.field}] ${e.message}`),
        });
        setSubmitting(false);
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  const createAdmin = async (param) => {
    const { values, resetForm, setErrors, setStatus, setSubmitting } = param;
    try {
      const response = await axios.post(
        `/admin/company/${company.id}/admin`,
        values
      );
      const responseData = response.data;
      if (get(responseData, "status", false) === true) {
        resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
        onAdminCreate(responseData.data);
      } else {
        setStatus({ sent: false });
        setErrors({
          submit: get(responseData, "message.messages.errors", []).map(
            (e) => `[${e.field}] ${e.message}`
          ),
        });
        setSubmitting(false);
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    if (admin) {
      updateAdmin({
        values,
        resetForm,
        setErrors,
        setStatus,
        setSubmitting,
      });
    } else {
      createAdmin({
        values,
        resetForm,
        setErrors,
        setStatus,
        setSubmitting,
      });
    }
  };
  const initialValues = {
    name: get(admin, "name", ""),
    email: get(admin, "email", ""),
    password: get(admin, "password", ""),
  };

  return (
    <Paper elevation={16} sx={{ mt: 8 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <>
            {status && status.sent && (
              <Alert severity="success" my={3}>
                Your data has been submitted successfully!
              </Alert>
            )}
            {status &&
              status.sent === false &&
              get(errors, "submit", []).map((e) => (
                <Alert severity="error" my={3}>
                  {e}
                </Alert>
              ))}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <TextField
                      name="name"
                      label="Admin Name*"
                      value={values.name === null ? "" : values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="email"
                      label="Username/Email*"
                      value={values.email === null ? "" : values.email}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>
                {admin ? null : (
                  <Grid container spacing={6}>
                    <Grid item md={6}>
                      <TextField
                        name="password"
                        label="Password*"
                        value={values.password || ""}
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    mt={3}
                  >
                    {admin ? "Update" : "Save"}
                  </Button>
                </Grid>
              </form>
            )}
          </>
        )}
      </Formik>
    </Paper>
  );
}

export default AdminForm;
