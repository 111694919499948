import React, { useCallback, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import CreateMeetingDialog from "./MeetingDialogs/CreateMeetingDialog";
import axios from "../../../utils/axios";
import { Button, Divider as MuiDivider, Grid, Typography } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { formateMeetingsData, isDoctor } from "../../../utils/helpers";
import MeetingPage from "./MeetingComponents/MeetingPage";
import useAuth from "../../../hooks/useAuth";

const Divider = styled(MuiDivider)(spacing);

function MeetingsList() {
  const [dialog, setDialog] = useState(false);
  const [meetings, setMeetings] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [tabValue, setTabValue] = useState("today");
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [patientData, setPatientData] = useState([]);

  const { user } = useAuth();

  const handleJoinMeeting = () => {
    const url = `${
      process.env.REACT_APP_WEBAPP_FRONTEND_URL
    }doctorMeeting/:meetingID=${
      selectedAppointment?.meetingId
    }/:passcode=${""}/:userID=${selectedAppointment?.patient?.id}/:adminID=${
      selectedAppointment?.doctor?.id
    }`;
    window.open(url, "_blank");
  };

  const getDoctors = useCallback(async () => {
    let doctorsData = [];
    try {
      setIsLoading(true);
      const customersAPIResponse = await axios.get("/doctor/list");
      if (customersAPIResponse.status && customersAPIResponse.data) {
        setIsLoading(false);
        doctorsData = customersAPIResponse.data?.data;
      }
    } catch (e) {
      setIsLoading(false);
      doctorsData = [];
    }
    return doctorsData;
  }, []);

  const getSpeciality = useCallback(async () => {
    let specialityData = [];
    try {
      const specialityAPIResponse = await axios.get("/speciality/list");
      if (specialityAPIResponse.status && specialityAPIResponse.data) {
        specialityData = specialityAPIResponse.data?.data;
      }
    } catch (e) {
      specialityData = [];
    }
    return specialityData;
  }, []);

  useEffect(() => {
    const getDoctorsData = async () => {
      const doctorsData = await getDoctors();
      setDoctors(doctorsData);
    };
    getDoctorsData();
  }, [getDoctors]);

  useEffect(() => {
    const getSpecialityData = async () => {
      const specialityData = await getSpeciality();
      setSpeciality(specialityData);
    };
    getSpecialityData();
  }, [getSpeciality]);

  const getMeetings = useCallback(async (filter) => {
    let meetingsData = [];
    try {
      setIsLoading(true);
      const { data } = await axios.post("/ringcentral/list", {
        filter,
      });
      setIsLoading(false);
      meetingsData = formateMeetingsData(data?.data);
    } catch (e) {
      setIsLoading(false);
      meetingsData = [];
    }
    return meetingsData;
  }, []);

  useEffect(() => {
    const getMeetingsData = async () => {
      const meetingsData = await getMeetings(tabValue);
      setMeetings(meetingsData);
    };
    getMeetingsData();
  }, [getMeetings, tabValue]);

  const dialogHandler = () => {
    setDialog((prev) => !prev);
  };

  const handleMeetingCreation = async () => {
    const meetingsData = await getMeetings(tabValue);
    setMeetings(meetingsData);
    setDialog(false);
  };

  const getCustomers = useCallback(async () => {
    let customersData = [];
    try {
      const customersAPIResponse = await axios.get("/admin/customers");
      if (customersAPIResponse?.data?.status)
        customersData = customersAPIResponse.data.data;
    } catch (e) {
      customersData = [];
    }
    return customersData;
  }, []);

  useEffect(() => {
    const getCustomersData = async () => {
      const customersData = await getCustomers();
      setPatientData(customersData);
    };
    getCustomersData();
  }, [getCustomers]);

  return (
    <React.Fragment>
      <Helmet title="Appointments" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Appointments
          </Typography>
        </Grid>
        <Grid item>
          <div>
            {isDoctor(user?.userType) ? null : (
              <Button
                onClick={dialogHandler}
                sx={{ m: 1 }}
                variant="contained"
                color="primary"
              >
                <AddIcon />
                Create Appointment
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
      <Divider my={3} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <MeetingPage
            meetings={meetings}
            tabValue={tabValue}
            setTabValue={setTabValue}
            isLoading={isLoading}
            selectedAppointment={selectedAppointment}
            setSelectedAppointment={setSelectedAppointment}
            handleJoinMeeting={handleJoinMeeting}
          />
        </Grid>
        <CreateMeetingDialog
          open={dialog}
          setOpen={setDialog}
          onClose={dialogHandler}
          onMeetingCreate={handleMeetingCreation}
          doctors={doctors}
          speciality={speciality}
          patientData={patientData}
        />
      </Grid>
    </React.Fragment>
  );
}

export default MeetingsList;
