import React from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

const AppointmentInfo = ({ selectedAppointment }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check for mobile view
  const isLarge = useMediaQuery(theme.breakpoints.up("xl")); // Check for large view

  return (
    <Stack direction={"column"} spacing={isLarge ? 5 : 2}>
      <Typography variant="h6">Appointment</Typography>

      <Stack direction={"row"} spacing={isLarge ? 5 : 2}>
        <Typography
          variant="body2"
          color={"gray"}
          sx={{ width: isMobile ? 140 : 120 }}
        >
          Title:
        </Typography>
        <Typography variant="body2" color={"gray"}>
          {selectedAppointment?.meetingName}
        </Typography>
      </Stack>

      <Stack direction={"row"} spacing={isLarge ? 5 : 2}>
        <Typography
          variant="body2"
          color={"gray"}
          sx={{ width: isMobile ? 140 : 120 }}
        >
          Date:
        </Typography>
        <Typography variant="body2" color={"gray"}>
          {selectedAppointment.startDate}
        </Typography>
      </Stack>
      <Stack direction={"row"} spacing={isLarge ? 5 : 2}>
        <Typography
          variant="body2"
          color={"gray"}
          sx={{ width: isMobile ? 140 : 120 }}
        >
          Time:
        </Typography>
        <Typography variant="body2" color={"gray"}>
          {selectedAppointment.startTime}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AppointmentInfo;
