import {
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Header = ({ removeAction, selectedAppointment }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check for mobile view
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p={isMobile ? "20px 5px" : "22px"}
    >
      <Stack direction={"row"} alignItems={"center"}>
        {isMobile && (
          <IconButton size="large" onClick={removeAction}>
            <ArrowBackIcon />
          </IconButton>
        )}
        {selectedAppointment && (
          <Typography variant="h4">
            {selectedAppointment.patient?.name}
          </Typography>
        )}
      </Stack>
      <Button variant="contained" disabled>
        Pull From Epic
      </Button>
    </Stack>
  );
};

export default Header;
