const Theme = {
  COLORS: {
    PURPLE: "#8735D8",
    GREEN: "#59B294",
    PRIMARY: "#4743E0",
    PRIMARY_BUTTON: "#2F80ED",
    ERROR: "#d32f2f",
    SUCCESS: "#00bf65",
    WARNING: "#FB6340",
    RED: "#ed7d85",
    MUTED: "#ADB5BD",
    OFFWHITE: "#F4F4F4",
    WHITE: "#FFFFFF",
    BLACK: "#000000",
    GRAY: "#6F6F6F",
    BORDERGRAY: "#E0E4EC",
    DARKGRAY: "#42505C",
    YELLOW: "#FF8A00",
    LIGHTYELLOW: "#FBEBB8",
    LIGHTGRAY: "#CCCCCC",
    STATSCARD: "#A7E0DA",
    FEROZI: "#e1f8ff",
    BLUE: "#2367D2",
    disabled: "#919EAB",
  },
};

export default Theme;
