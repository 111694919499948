import React from "react";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import HistoryTabs from "./HistoryTabs";
import {
  Box,
  // Stack,
  Typography,
  // useMediaQuery,
  // useTheme,
} from "@mui/material";
import MeetingDetails from "./MeetingDetails";
import Notes from "./Notes/Notes";
import Summary from "./Summary/Summary";

const HistoryDetails = ({ selectedAppointment, historyTab, handleChange }) => {
  // const theme = useTheme();
  // const isLarge = useMediaQuery(theme.breakpoints.up("xl")); // Check for large view

  return (
    <Box>
      <TabContext value={historyTab}>
        <HistoryTabs value={historyTab} handleChange={handleChange} />
        <TabPanel value="appointment">
          <MeetingDetails
            selectedAppointment={selectedAppointment}
            tempDisabled={true}
          />
        </TabPanel>

        <TabPanel value="notes">
          <Notes meetingId={selectedAppointment.id} />
        </TabPanel>

        <TabPanel value="summary">
          <Summary meetingId={selectedAppointment.id} />
        </TabPanel>
        <TabPanel value="transcript">
          <Typography
            variant="h3"
            color={"gray"}
            sx={{ textAlign: "center", mt: 10 }}
          >
            No Data Found!
          </Typography>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default HistoryDetails;
