import * as React from "react";
import {
  Box,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import CloseIcon from "@mui/icons-material/Close";
import { Helmet } from "react-helmet-async";
import CompanyForm from "./CompanyForm";

export default function AddCompanyDialog(props) {
  const { open, setOpen, onClose, company, onCompanyUpdate, onCompanyCreate } =
    props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <PerfectScrollbar>
      <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={10}></Grid>
              <Grid item xs={2}>
                <CloseIcon
                  onClick={handleClose}
                  style={{
                    float: "right",
                    marginTop: 1,
                    marginBottom: 8,
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", typography: "body1" }}>
            {company ? (
              <>
                <Helmet title="Edit Company" />
                <Typography variant="h3" gutterBottom display="inline">
                  Edit Company's Information
                </Typography>
              </>
            ) : (
              <>
                <Helmet title="Add Company" />
                <Typography variant="h3" gutterBottom display="inline">
                  Add Company's Information
                </Typography>
              </>
            )}
            <CompanyForm
              company={company}
              onCompanyUpdate={onCompanyUpdate}
              onCompanyCreate={onCompanyCreate}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </PerfectScrollbar>
  );
}
