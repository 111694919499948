import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Chip as MuiChip,
  IconButton,
  Grid,
  Paper,
  Pagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { RemoveRedEye as RemoveRedEyeIcon } from "@mui/icons-material";
import { green, red } from "@mui/material/colors";
import styled from "@emotion/styled";
import moment from "moment";
import { isString } from "lodash";

const Chip = styled(MuiChip)`
  background: ${(props) => props.passed && green[500]};
  background: ${(props) => props.failed && red[500]};
  color: ${(props) => props.theme.palette.common.white};
`;
function LogTable({
  customerLogs,
  onMediaSelection,
  totalPages,
  page,
  setPage,
  lastPage,
}) {
  const getStats = (log, type) => {
    let stat;
    if (log.type === "AUDIO_VERIFICATION" || log.type === "INCODE_ONBORDING") {
      stat = JSON.parse(log.extra_details);
    } else {
      stat = "-";
      return stat;
    }
    if (type === "prob") {
      if (isNaN(stat?.probability)) {
        return "-";
      } else {
        return stat?.probability.toFixed(3);
      }
    } else {
      if (isNaN(stat?.score)) {
        return "-";
      } else {
        if (isString(stat?.score)) {
          return stat.score;
        } else {
          return stat?.score.toFixed(3);
        }
      }
    }
  };
  return (
    <PerfectScrollbar>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{ my: 2 }}
      >
        <Grid item xs={12}>
          <Paper elevation={16}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date/Time</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Probability</TableCell>
                  <TableCell>Score</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Media</TableCell>
                  {/* <TableCell>Audio</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {customerLogs.map((log) => (
                  <TableRow hover key={log.id}>
                    <TableCell>
                      {moment(log.created_at).format("DD, MMM YYYY hh:mm A")}
                    </TableCell>
                    <TableCell>
                      {log.type === "SELFIE_VERIFICATION"
                        ? "SELFIE VERIFICATION"
                        : "VOICE VERIFICATION"}
                    </TableCell>
                    <TableCell>{getStats(log, "prob")}</TableCell>
                    <TableCell>{getStats(log, "score")}</TableCell>
                    <TableCell>
                      {log.status === 1 ? (
                        <Chip
                          size="small"
                          sx={{ mb: 1 }}
                          label="Passed"
                          passed={+true}
                        />
                      ) : (
                        <Chip
                          size="small"
                          sx={{ mb: 1 }}
                          label="Failed"
                          failed={+true}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          onMediaSelection(log.file.path_full, log.type)
                        }
                        aria-label="View Video"
                        size="large"
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
      {totalPages !== null ? (
        totalPages !== 0 ? (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item>
              <Pagination
                page={page}
                onChange={(event, value) => {
                  setPage(value);
                }}
                sx={{ mt: 2 }}
                count={lastPage}
                size="small"
                color="secondary"
              />
            </Grid>
          </Grid>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Typography variant="h4"> No Data</Typography>
          </div>
        )
      ) : null}
    </PerfectScrollbar>
  );
}

export default LogTable;
