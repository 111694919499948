import moment from "moment";
import {
  FRONTEND_DATE_FORMAT,
  // BACKEND_DATE_FORMAT,
  PICKER_DATE_FORMAT,
} from "../constants";

export const isSuperAdmin = (userType) => {
  // if type is 1 it means user is Super Admin
  return userType === 1;
};

export const isEmployee = (userType) => {
  // if type is 2 it means user is Employee
  return userType === 2;
};

export const isDoctor = (userType) => {
  // if type is 5 it means user is Doctor
  return userType === 5;
};

export const dateTimePickerformat = (date) => {
  // this format is required for DateTimePicker
  return moment(date, FRONTEND_DATE_FORMAT).format(PICKER_DATE_FORMAT);
};

export const errorMessageHandler = (responseData) => {
  let errorArray = [];
  if (responseData.errors) {
    responseData.errors.map((error) =>
      errorArray.push({ field: error.field, message: error.message })
    );
  } else if (responseData?.message) {
    errorArray.push({ message: responseData.message });
  } else {
    errorArray.push({ message: "Something went wrong!" });
  }
  return errorArray;
};

export const compareStartEndTime = (start, end) => {
  const now = new Date(); // Get the current datetime
  // const relaxation = 5 * 60000; // 5 minutes in milliseconds

  // Convert start to a Date object if it's not already
  // const startDate = start instanceof Date ? start : new Date(start);

  // const relaxedStart = new Date(startDate.getTime() + relaxation); // Add relaxation time to start time

  const currentTime = moment(now);
  const startTime = moment(start);
  const endTime = moment(end);
  // const relaxedStartTime = moment(relaxedStart);

  // return true if Start time is in the past of end time
  // and the end time should be later than the start time.
  // and the start time should be in the present or future with 5 minutes relaxtion.
  return (
    // relaxedStartTime.isSameOrAfter(currentTime) &&
    endTime.isSameOrAfter(currentTime) && endTime.isAfter(startTime)
  );
};

export const formateCustomerDropDown = (data) => {
  const formatedData = [];
  data.map((person) =>
    formatedData.push({
      id: person?.id,
      name: person?.name,
      email: person?.email,
    })
  );
  return formatedData;
};

export const formateMeetingsData = (data) => {
  const formatedData = [];
  data.map((meeting) =>
    formatedData.push({
      id: meeting?.id,
      meetingId: parseInt(meeting?.meeting_id),
      bridgeId: meeting?.bridge_id,
      meetingName: meeting?.meeting_name,
      startDate: meeting?.start_date
        ? moment(meeting.start_date).format("ll")
        : "-",
      startTime: meeting?.start_time
        ? moment(meeting.start_time).format("LT")
        : "-",
      description: meeting?.meeting_description,
      patient: meeting.patient,
      doctor: meeting.doctor,
    })
  );
  return formatedData;
};

export const formateCreateMeetingsData = (data) => {
  const formatedData = {
    id: data?.id,
    bridgeId: data?.bridge_id,
    meetingId: parseInt(data?.meeting_id),
    dataId: data?.data_id,
    name: data?.meeting_name ?? "",
    createdAt: data?.created_at ? moment(data.created_at).format("LLL") : "-",
    updatedAt: data?.updated_at ? moment(data.updated_at).format("LLL") : "-",
    startTime: data?.start_time ? moment(data.start_time).format("LLL") : "-",
    endTime: data?.end_time ? moment(data.end_time).format("LLL") : "-",
    description: data?.data_summery ?? "",
    attendees: data?.patient_id ?? null,
    patientId: data?.patient_id ?? null,
    doctorId: data?.doctor_id ?? null,
  };

  return formatedData;
};
