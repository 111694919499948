import React from "react";
import DashboardLayout from "./layouts/Dashboard";

// import EmployeeList from "./pages/pages/Employees";
import AuthLayout from "./layouts/Auth";
import SignIn from "./pages/auth/SignIn";
import Dashboard from "./pages/pages/Dashboard";
import Customers from "./pages/pages/Customers/Customers";
import Meetings from "./pages/pages/Meetings2/Meetings";
import Doctors from "./pages/pages/Doctors/Doctors";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import Companies from "./pages/pages/SuperAdmin/Companies";
import NotificationSetting from "./pages/pages/Settings/NotificationSetting";
import BrandingSetting from "./pages/pages/Settings/BrandingSetting";

const routes = [
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Customers />,
      },
      {
        path: "appointments",
        element: <Meetings />,
      },
    ],
  },
  {
    path: "customers",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Customers />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
    ],
  },
  {
    path: "/setting",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "branding",
        element: <BrandingSetting />,
      },
      {
        path: "notification",
        element: <NotificationSetting />,
      },
    ],
  },
  {
    path: "companies",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Companies />,
      },
    ],
  },
  {
    path: "doctors",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Doctors />,
      },
    ],
  },
];

export default routes;
