import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogTitle, DialogContent, Box } from "@mui/material";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { appConfig } from "../../../config";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

function SimpleTableDemo({ rows }) {
  return (
    <Card mb={6}>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Date/Time</TableCell>
              <TableCell align="right">Sessions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">
                  {moment(row.created_at).format("DD, MMM YYYY hh:mm A")}
                </TableCell>
                <TableCell align="right">
                  <Box sx={{ display: "inline" }}>
                    <IconButton
                      onClick={() =>
                        window.open(`${appConfig.INCODE_URL}/${row.session_id}`)
                      }
                      size="small"
                    >
                      <OpenInNewIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}
function SimpleTable({ rows }) {
  return (
    <React.Fragment>
      <Helmet title="Simple Table" />
      <Typography variant="h3" gutterBottom display="inline">
        Incode pending sessions
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <SimpleTableDemo rows={rows} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const IncodeDialog = ({ onClose, open, inCodeRows }) => {
  const handleOnClose = () => {
    onClose(false);
  };
  return (
    <>
      <Dialog onClose={handleOnClose} open={open} fullWidth maxWidth="md">
        <DialogTitle>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={10}></Grid>
              <Grid item xs={2}>
                <CloseIcon
                  onClick={handleOnClose}
                  style={{
                    float: "right",
                    marginTop: 1,
                    marginBottom: 8,
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: "100%",
              height: "auto",
              typography: "body1",
            }}
          >
            {!inCodeRows.length ? (
              <Typography align="center">No data exist</Typography>
            ) : (
              <SimpleTable rows={inCodeRows} />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default IncodeDialog;
