import axios from "../../../../utils/axios";

export const getTabDetails = async (key, meetingID) => {
  try {
    const resp = await axios.post("/past-meeting-logs/details/", {
      key,
      meeting_id: meetingID,
    });

    if (resp.data.status) return resp.data.data;
    else return resp.data;
  } catch (err) {
    console.log(err);
    return false;
  }
};
