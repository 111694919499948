import React from "react";
import { Helmet } from "react-helmet-async";
import { Grid, Typography, Button } from "@mui/material";
import DoctorForm from "./DoctorForm";

function UpdateDoctorForm({ doctor, speciality, onDoctorUpdate, onClick }) {
  return (
    <React.Fragment>
      <Helmet title="Edit Doctor" />
      <Grid
        container
        sx={{ m: 1 }}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Edit Doctor's Information
          </Typography>
        </Grid>
        <Grid item sx={{ mr: 4 }}>
          <Button size="small" onClick={onClick} variant="outlined">
            Close
          </Button>
        </Grid>

        <Grid item xs={12} sx={{ mt: 8 }}>
          <DoctorForm
            onDoctorUpdate={onDoctorUpdate}
            doctor={doctor}
            speciality={speciality}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UpdateDoctorForm;
