import React from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import { get } from "lodash";
import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "../../../utils/axios";
import { errorMessageHandler } from "../../../utils/helpers";
import Theme from "../../../utils/theme";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function DoctorForm({ doctor, speciality, onDoctorCreate, onDoctorUpdate }) {
  let validationSchema = null;

  const validationObject = {
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    mobile_phone: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    speciality_id: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    address_line_1: Yup.string().required("Required"),
  };
  if (doctor) {
    validationSchema = Yup.object().shape(validationObject);
  } else {
    validationObject.password = Yup.string()
      .min(8, "Must be at least 8 characters")
      .max(255)
      .required("Required");
    validationSchema = Yup.object().shape(validationObject);
  }
  const updateDoctor = async (param) => {
    const { values, setErrors, setStatus, setSubmitting } = param;
    try {
      const response = await axios.patch(`/doctor/update/${doctor.id}`, values);
      const responseData = response.data;
      if (get(responseData, "status", false) === true) {
        setStatus({ sent: true });
        setSubmitting(false);
        onDoctorUpdate();
      } else {
        setStatus({ sent: false });
        const apiErrors = errorMessageHandler(responseData);
        if (apiErrors.length === 0) {
          apiErrors.push({ field: "Update", message: "Operation failed." });
        }
        setErrors({
          submit: apiErrors.map((e) =>
            e.field ? `[${e.field}] ${e.message}` : e.message
          ),
        });
        setSubmitting(false);
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  const createDoctor = async (param) => {
    const { values, resetForm, setErrors, setStatus, setSubmitting } = param;
    try {
      const response = await axios.post("/doctor/create", values);
      const responseData = response.data;
      if (get(responseData, "status", false) === true) {
        resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
        onDoctorCreate(responseData.data);
      } else {
        setStatus({ sent: false });
        const apiErrors = errorMessageHandler(responseData);
        if (apiErrors.length === 0) {
          apiErrors.push({ field: "Create", message: "Operation failed." });
        }
        setErrors({
          submit: apiErrors.map((e) =>
            e.field ? `[${e.field}] ${e.message}` : e.message
          ),
        });
        setSubmitting(false);
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: [error.message] });
      setSubmitting(false);
    }
  };
  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    if (doctor) {
      updateDoctor({
        values,
        resetForm,
        setErrors,
        setStatus,
        setSubmitting,
      });
    } else {
      createDoctor({
        values,
        resetForm,
        setErrors,
        setStatus,
        setSubmitting,
      });
    }
  };
  const initialValues = {
    first_name: get(doctor, "first_name", ""),
    last_name: get(doctor, "last_name", ""),
    mobile_phone: get(doctor, "mobile_phone", ""),
    email: get(doctor, "email", ""),
    speciality_id: get(doctor, "speciality_id", ""),
    gender: get(doctor, "gender", 1),
    address_line_1: get(doctor, "address_line_1", ""),
    password: get(doctor, "password", ""),
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
        status,
      }) => (
        <>
          {status && status.sent && (
            <Alert severity="success" my={3}>
              Your data has been submitted successfully!
            </Alert>
          )}
          {status &&
            status.sent === false &&
            get(errors, "submit", []).map((e) => (
              <Alert key={e} severity="error" my={3}>
                {e}
              </Alert>
            ))}

          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={3}
              >
                <Grid item xs={6}>
                  <TextField
                    name="first_name"
                    label="First Name*"
                    value={values.first_name === null ? "" : values.first_name}
                    error={Boolean(touched.first_name && errors.first_name)}
                    fullWidth
                    helperText={touched.first_name && errors.first_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="last_name"
                    label="Last Name*"
                    value={values.last_name === null ? "" : values.last_name}
                    error={Boolean(touched.last_name && errors.last_name)}
                    fullWidth
                    helperText={touched.last_name && errors.last_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name="mobile_phone"
                    label="Phone No*"
                    value={
                      values.mobile_phone === null ? "" : values.mobile_phone
                    }
                    error={Boolean(touched.mobile_phone && errors.mobile_phone)}
                    fullWidth
                    helperText={touched.mobile_phone && errors.mobile_phone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name="email"
                    label="Email*"
                    value={values.email === null ? "" : values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    variant="outlined"
                    my={2}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    sx={{ mt: 2 }}
                    error={Boolean(
                      touched.speciality_id && errors.speciality_id
                    )}
                  >
                    <InputLabel id="Gender">Speciality*</InputLabel>
                    <Select
                      id="Speciality"
                      name="speciality_id"
                      value={values.speciality_id}
                      label="Speciality*"
                      onChange={handleChange}
                      fullWidth
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      }}
                    >
                      {speciality.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.speciality_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormHelperText sx={{ pl: 3, color: Theme.COLORS.ERROR }}>
                    {touched.speciality_id && errors.speciality_id}
                  </FormHelperText>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="Gender">Gender*</InputLabel>
                    <Select
                      labelId="Gender"
                      id="Gender"
                      name="gender"
                      value={values.gender}
                      label="Gender*"
                      onChange={handleChange}
                      error={Boolean(touched.gender && errors.gender)}
                      fullWidth
                    >
                      <MenuItem key={1} value={1}>
                        Male
                      </MenuItem>
                      <MenuItem key={0} value={0}>
                        Female
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {doctor ? null : (
                  <Grid item xs={6}>
                    <TextField
                      name="password"
                      label="Password*"
                      value={values.password || ""}
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <TextField
                    name="address_line_1"
                    label="Address*"
                    value={
                      values.address_line_1 === null
                        ? ""
                        : values.address_line_1
                    }
                    error={Boolean(
                      touched.address_line_1 && errors.address_line_1
                    )}
                    fullWidth
                    helperText={touched.address_line_1 && errors.address_line_1}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  mt={3}
                >
                  {doctor ? "Update" : "Save"}
                </Button>
              </Grid>
            </form>
          )}
        </>
      )}
    </Formik>
  );
}

export default DoctorForm;
