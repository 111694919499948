import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brandings: null,
  globalLoader: false,
};

export const MasterSlice = createSlice({
  name: "MasterSlice",
  initialState,
  reducers: {
    setBrandings: (state, action) => {
      state.brandings = action.payload;
    },
    setGlobalLoader: (state) => {
      state.globalLoader = !state.globalLoader;
    },
  },
});

export const { setBrandings, setGlobalLoader } = MasterSlice.actions;

export default MasterSlice.reducer;
