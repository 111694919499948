import { Users } from "react-feather";

const pagesSection = [
  {
    href: "/companies",
    icon: Users,
    title: "Companies",
  },
];

const navItems = [
  {
    pages: pagesSection,
  },
];

export default navItems;
