import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import MasterSlice from "./slices/MasterSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    Master: MasterSlice,
  },
});
