import React, { useCallback, useEffect } from "react";
import {
  Alert,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { ReminderSchedule } from "../../../constants/constants";
import axios from "../../../utils/axios";
import { LoadingButton } from "@mui/lab";

const NotificationSetting = () => {
  const [loader, setLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [apiMessage, setApiMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const [state, setState] = useState({
    patientNotification: true,
    patientReminderText: "",
    reminderScheduled: 1440,
    patientNotifyMethod: "SMS",
    providerNotification: true,
    providerNotifyMethod: "SMS",
    providerReminderText: "",
  });

  const formatData = (data) => {
    console.log("data: ", typeof data.appointment_reminder_schedule);
    setState({
      patientNotification: data.appointment_reminder,
      patientReminderText: data.reminder_body,
      reminderScheduled: data.appointment_reminder_schedule,
      patientNotifyMethod: data.reminder_method,
      providerNotification: data.notify_provider,
      providerNotifyMethod: data.provider_notification_method,
      providerReminderText: data.provider_reminder_body,
    });
  };

  const getSettings = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get("/api/notification-settings");
      if (data.status) {
        formatData(data.data);
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const saveSettings = useCallback(async () => {
    try {
      setLoader(true);
      const payload = {
        appointment_reminder: state.patientNotification,
        reminder_body: state.patientReminderText,
        appointment_reminder_schedule: state.reminderScheduled,
        reminder_method: state.patientNotifyMethod,
        notify_provider: state.providerNotification,
        provider_notification_method: state.providerNotifyMethod,
        provider_reminder_body: state.providerReminderText,
      };
      const { data } = await axios.post("/api/notification-settings", payload);
      if (data.status) {
        setShowAlert(true);
        setShowStatus(true);
        setApiMessage(data.message);
      } else {
        setShowAlert(true);
        setShowStatus(false);
        setApiMessage(data.message);
      }
    } catch (e) {
      console.log("error", e);
      setApiMessage(e);
    } finally {
      setLoader(false);
    }
  }, [state]);

  const handleSnackbar = () => {
    setShowAlert(false);
  };

  return (
    <React.Fragment>
      <Helmet title="Appointments" />
      <Grid container component={Paper} sx={{ gap: 5 }} p={10} my={5}>
        <Typography variant="h6">Notification</Typography>
        <Divider sx={{ width: "100%" }} />
        {isLoading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: 400 }}
          >
            <CircularProgress m={2} color="secondary" />
          </Grid>
        ) : (
          <>
            <Typography variant="body2" color={"gray"}>
              Appointment Reminders
            </Typography>
            <Grid
              container
              direction={"column"}
              justifyContent="space-between"
              alignItems={"flex-start"}
              sx={{ border: "1px solid #E0E0E0", gap: 5 }}
              p={5}
            >
              <Typography variant="h6">
                Enable SMS Appointment Reminders
              </Typography>
              <Grid
                container
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={5}
              >
                <Grid item xs={12} md={10}>
                  <Typography variant="body2" color={"gray"}>
                    If you would like RingCentral to manage the transmission of
                    telehealth appointment reminders via SMS text message,
                    please enable this feature.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Switch
                    checked={state.patientNotification}
                    onChange={(e) =>
                      setState({
                        ...state,
                        patientNotification: e.target.checked,
                      })
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ width: "100%" }} />
              <Typography variant="h6">SMS Reminder Text</Typography>
              <Typography variant="body2" color={"gray"}>
                Please enter the text to be used to remind patients of upcoming
                appointments.
              </Typography>
              <TextField
                fullWidth
                multiline
                value={state.patientReminderText}
                onChange={(e) =>
                  setState({ ...state, patientReminderText: e.target.value })
                }
                rows={3}
                variant="filled"
                placeholder="Please enter the text to be used to remind patients of upcoming apppintments.This is a reminder that you have an appointment with { doctor's name } on
                            { appointment date } at { appointment time }. If you would like to make changes please use this link: { manage link}"
              />
              <Divider sx={{ width: "100%" }} />
              <Typography variant="h6">
                Appointment Reminder Schedule
              </Typography>
              <Grid
                container
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={5}
              >
                <Grid item xs={12} md={10}>
                  <Typography variant="body2" color={"gray"}>
                    If you would like RingCentral to manage the transmission of
                    telehealth appointment reminders via SMS text message,
                    please enable this feature.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      value={state.reminderScheduled}
                      // label="Doctor"
                      onChange={(e) =>
                        setState({
                          ...state,
                          reminderScheduled: e.target.value,
                        })
                      }
                    >
                      {ReminderSchedule?.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Divider sx={{ width: "100%" }} />
              <Typography variant="h6">
                Preferred Notification Method
              </Typography>
              <Grid
                container
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={5}
              >
                <Grid item xs={12} md={10}>
                  <Typography variant="body2" color={"gray"}>
                    How would providers like to be notified when patients are
                    waiting?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControl fullWidth>
                    <Select
                      labelid="Doctor"
                      id="Doctor"
                      name="doctor"
                      size="small"
                      value={state.patientNotifyMethod}
                      onChange={(e) =>
                        setState({
                          ...state,
                          patientNotifyMethod: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="SMS">SMS</MenuItem>
                      <MenuItem value="EMAIL">Email</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* provider notifications */}

            <Typography variant="body2" color={"gray"}>
              Provider Reminders
            </Typography>
            <Grid
              container
              direction={"column"}
              justifyContent="space-between"
              alignItems={"flex-start"}
              sx={{ border: "1px solid #E0E0E0", gap: 5 }}
              p={5}
            >
              <Typography variant="h6">
                Notify Providers When Patient Is Waiting
              </Typography>
              <Grid
                container
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={5}
              >
                <Grid item xs={12} md={10}>
                  <Typography variant="body2" color={"gray"}>
                    Enable this setting if you would like RingCentral to notify
                    providers when patients enter the virtual exam room.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Switch
                    checked={state.providerNotification}
                    onChange={(e) =>
                      setState({
                        ...state,
                        providerNotification: e.target.checked,
                      })
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
              </Grid>

              <Divider sx={{ width: "100%" }} />

              <Typography variant="h6">
                Preferred Notification Method
              </Typography>
              <Grid
                container
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={5}
              >
                <Grid item xs={12} md={10}>
                  <Typography variant="body2" color={"gray"}>
                    How would providers like to be notified when patients are
                    waiting?
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControl fullWidth>
                    <Select
                      labelid="Doctor"
                      id="Doctor"
                      name="doctor"
                      size="small"
                      value={state.providerNotifyMethod}
                      onChange={(e) =>
                        setState({
                          ...state,
                          providerNotifyMethod: e.target.value,
                        })
                      }
                    >
                      <MenuItem value="SMS">SMS</MenuItem>
                      <MenuItem value="EMAIL">Email</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Divider sx={{ width: "100%" }} />
              <Typography variant="h6">Reminder Text</Typography>

              <Typography variant="body2" color={"gray"}>
                Please enter the text to be used to remind patients of upcoming
                appointments.
              </Typography>

              <TextField
                fullWidth
                multiline
                value={state.providerReminderText}
                onChange={(e) =>
                  setState({ ...state, providerReminderText: e.target.value })
                }
                rows={3}
                variant="filled"
                placeholder="{ patient name} is currently waiting for you in their telehealth session. Join the meeting using this link: { provider join url}"
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                onClick={saveSettings}
                loading={loader}
                variant="contained"
                sx={{ float: "right", minWidth: 100 }}
              >
                <span>Save</span>
              </LoadingButton>
            </Grid>
          </>
        )}
      </Grid>
      <Snackbar
        open={showAlert}
        autoHideDuration={3000}
        onClose={handleSnackbar}
      >
        {showStatus ? (
          <Alert severity="success" sx={{ width: "100%" }}>
            {apiMessage}
          </Alert>
        ) : (
          <Alert severity="error" sx={{ width: "100%" }}>
            {apiMessage}
          </Alert>
        )}
      </Snackbar>
    </React.Fragment>
  );
};

export default NotificationSetting;
