import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import { isSuperAdmin, isEmployee, isDoctor } from "../../utils/helpers";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { pathname } = useLocation();
  const { isAuthenticated, isInitialized, user } = useAuth();

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }
  if (user) {
    if (isEmployee(user.userType) && pathname === "/companies") {
      return <Navigate to="/" />;
    } else if (isSuperAdmin(user.userType) && pathname === "/") {
      return <Navigate to="/companies" />;
    } else if (isDoctor(user.userType) && pathname === "/") {
      return <Navigate to="/dashboard" />;
    }
    // else if (isSuperAdmin(user.userType) && pathname === "/employees") {
    //   return <Navigate to="/companies" />;
    // }
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
