import * as React from "react";
import {
  Box,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MeetingForm from "./MeetingForm";
import { Helmet } from "react-helmet-async";

export default function CreateMeetingDialog(props) {
  const {
    open,
    setOpen,
    onClose,
    onMeetingCreate,
    doctors,
    speciality,
    patientData,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check for mobile view

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      // PaperProps={{ sx: { height: 500 } }}
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <CloseIcon
                onClick={handleClose}
                style={{
                  float: "right",
                  marginTop: 1,
                  marginBottom: 8,
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Helmet title="Add Appointment" />
          <Typography
            variant={isMobile ? "h6" : "h4"}
            gutterBottom
            display="inline"
          >
            Schedule Appointment
          </Typography>
          <Grid sx={{ mt: 8 }}>
            <MeetingForm
              onMeetingCreate={onMeetingCreate}
              doctors={doctors}
              speciality={speciality}
              patientData={patientData}
            />
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
