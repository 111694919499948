import * as React from "react";
import {
  Box,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import CloseIcon from "@mui/icons-material/Close";
import AdminForm from "./AdminForm";

export default function s(props) {
  const {
    open,
    setOpen,
    onClose,
    admin,
    company,
    onAdminUpdate,
    onAdminCreate,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
      <DialogTitle>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <CloseIcon
                onClick={handleClose}
                style={{
                  float: "right",
                  marginTop: 1,
                  marginBottom: 8,
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%", typography: "body1" }}>
          {admin ? (
            <>
              <Helmet title="Edit Admin" />
              <Typography variant="h3" gutterBottom display="inline">
                Edit Admin's Information
              </Typography>
            </>
          ) : (
            <>
              <Helmet title="Add Admin" />
              <Typography variant="h3" gutterBottom display="inline">
                Add Admin's Information
              </Typography>
            </>
          )}
          <AdminForm
            admin={admin}
            company={company}
            onAdminCreate={onAdminCreate}
            onAdminUpdate={onAdminUpdate}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
