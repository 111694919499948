import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  IconButton,
  Dialog,
} from "@mui/material";
import moment from "moment";
import { get } from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";
import { CheckCircleOutline, Cancel, RemoveRedEye } from "@mui/icons-material";

const getMedia = (mediaId, arr) => {
  let arrToReturn;
  if (mediaId !== null) {
    arrToReturn = arr.filter((filt) => filt.media_id === mediaId);
  } else {
    arrToReturn = null;
  }
  return arrToReturn;
};
const getMediaFail = (mediaId, arr) => {
  let arrToReturn;
  if (mediaId !== null) {
    arrToReturn = arr.find(
      (filt) =>
        filt.media_type === "INCODE_ONBORDING_ID_FRONT" &&
        filt.media_id === mediaId
    );
  } else {
    arrToReturn = null;
  }
  return arrToReturn?.path_full ?? "";
};

const getIdFront = (arr) => {
  let arrToReturn;
  arrToReturn = arr.find(
    (filt) => filt.media_type === "INCODE_ONBORDING_ID_FRONT"
  );
  return arrToReturn?.path_full ?? "";
};

const getIdBack = (arr) => {
  let arrToReturn;
  arrToReturn = arr.find(
    (filt) => filt.media_type === "INCODE_ONBORDING_ID_BACK"
  );
  return arrToReturn?.path_full ?? "";
};

function OnboardingData(props) {
  const [path, setPath] = useState("");
  const [dialog, setDialog] = useState(false);
  const [hoverIcon1, setHoverIcon1] = useState(false);
  const [hoverIcon2, setHoverIcon2] = useState(false);
  const [onBoardingHistory, setOnBoardingHistory] = useState([]);

  const { customer } = props;
  const on_boarding = customer?.on_boarding
    ? customer?.on_boarding
        .sort(function (a, b) {
          return b.id - a.id;
        })
        .find((row) => {
          return row.type === "INCODE_ONBORDING";
        })
    : null;
  const incode_onboard = on_boarding?.file?.path_full;
  const files = get(customer, "files", {});
  const media_id = on_boarding?.media_id;
  const docs = getMedia(media_id, files);

  // const getVideo = () => {
  //   let arrToReturn;
  //   arrToReturn = customer?.files?.filter(
  //     (filt) => filt.media_type === "VIDEO_ONBOARDING"
  //   );
  //   return arrToReturn[arrToReturn?.length - 1]?.path_full ?? "";
  // };

  const getAudio = (arr) => {
    let arrToReturn;
    arrToReturn = arr.find((filt) => filt.media_type === "AUDIO_ONBOARDING");
    return arrToReturn?.path_full ?? "";
  };

  const onMediaSelection = (path) => {
    setDialog(true);
    setPath(path);
  };

  useEffect(() => {
    if (customer?.on_boarding?.length) {
      const newArray = [];
      customer.on_boarding.forEach((element) => {
        if (element.type === "INCODE_ONBORDING") {
          newArray.push(element);
        }
      });
      setOnBoardingHistory(newArray);
    }
  }, [customer?.on_boarding]);

  return (
    <PerfectScrollbar>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={3}
        sx={{ marginTop: ".5px" }}
      >
        <Grid item xs={12}>
          <Paper elevation={16} sx={{ padding: 1 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={3}
              sx={{ padding: 1 }}
            >
              <Grid item xs={12}>
                <h3>On Boardings</h3>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={"body2"}>
                  Uploaded at:
                  {on_boarding
                    ? moment(on_boarding.created_at).format(
                        "DD, MMM YYYY hh:mm A"
                      )
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" label="Start">
                    Selfie
                  </FormLabel>
                  {on_boarding ? (
                    <Box
                      component="span"
                      sx={{
                        p: 2,
                        // marginLeft: 15,
                        border: "1px dashed grey",
                        display: "flex",
                      }}
                    >
                      <a
                        href={incode_onboard}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={incode_onboard} alt="" width={200} />
                      </a>
                    </Box>
                  ) : (
                    "No Image"
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" label="center">
                    Audio
                  </FormLabel>
                  <Box
                    component="span"
                    sx={{
                      display: "flex",
                      marginTop: 2,
                      maxWidth: "80%",
                    }}
                  >
                    <audio src={getAudio(docs)} controls></audio>
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" label="Start">
                    ID Front
                  </FormLabel>
                  {on_boarding ? (
                    <Box
                      component="span"
                      sx={{
                        p: 2,
                        border: "1px dashed grey",
                        display: "flex",
                      }}
                    >
                      <a
                        href={getIdFront(docs)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={getIdFront(docs)} alt="" width={200} />
                      </a>
                    </Box>
                  ) : (
                    "No Image"
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" label="Start">
                    ID Back
                  </FormLabel>
                  {on_boarding ? (
                    <Box
                      component="span"
                      sx={{
                        p: 2,
                        border: "1px dashed grey",
                        display: "flex",
                      }}
                    >
                      <a
                        href={getIdBack(docs)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={getIdBack(docs)} alt="" width={200} />
                      </a>
                    </Box>
                  ) : (
                    "No Image"
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          flexDirection: "column",
          display: "flex",
          alignItems: "flex-start",
          marginTop: 5,
        }}
      >
        <h3 style={{ marginLeft: 10 }}>History</h3>
        {onBoardingHistory.length
          ? onBoardingHistory.map((prop, index) => {
              return (
                <Grid item xs={12} key={index}>
                  {prop.status === 1 ? (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IconButton disabled aria-label="success" size="small">
                        <CheckCircleOutline
                          fontSize="large"
                          sx={{ color: "#2E7D32" }}
                        />
                      </IconButton>
                      <Grid>
                        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                          Incode onboarding
                        </Typography>
                        <Typography sx={{ fontSize: 10, color: "#949393" }}>
                          Uploaded at:
                          {moment(on_boarding.created_at).format(
                            "DD, MMM YYYY hh:mm A"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "flex-start",
                        marginTop: 2,
                      }}
                    >
                      <IconButton disabled aria-label="success" size="small">
                        <Cancel fontSize="large" sx={{ color: "red" }} />
                      </IconButton>
                      <Grid>
                        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                          {prop?.reason
                            ? prop?.reason
                            : "CNIC doesn't match with the selfie"}
                        </Typography>
                        <Typography sx={{ fontSize: 10, color: "#949393" }}>
                          Uploaded at:
                          {moment(prop.created_at).format(
                            "DD, MMM YYYY hh:mm A"
                          )}
                        </Typography>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                            marginTop: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 13,
                              color: "#000",
                              fontWeight: "medium",
                            }}
                          >
                            Documents:
                          </Typography>
                          {!hoverIcon1 ? (
                            <img
                              alt=""
                              onMouseOver={() => {
                                setHoverIcon2(false);
                                setHoverIcon1(true);
                              }}
                              onClick={() =>
                                onMediaSelection(prop.file.path_full)
                              }
                              src={prop.file.path_full}
                              style={{
                                width: 25,
                                height: 25,
                                borderRadius: 2,
                                marginLeft: 8,
                                marginRight: 8,
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <IconButton
                              size="small"
                              onMouseLeave={() => {
                                setHoverIcon1(false);
                              }}
                              onClick={() =>
                                onMediaSelection(prop.file.path_full)
                              }
                            >
                              <RemoveRedEye fontSize="small" />
                            </IconButton>
                          )}
                          {!hoverIcon2 ? (
                            <img
                              alt=""
                              onMouseOver={() => {
                                setHoverIcon1(false);
                                setHoverIcon2(true);
                              }}
                              onClick={() =>
                                onMediaSelection(
                                  getMediaFail(prop.file.id, files)
                                )
                              }
                              src={getMediaFail(prop.file.id, files)}
                              style={{
                                width: 25,
                                height: 25,
                                borderRadius: 2,
                                cursor: "pointer",
                              }}
                            />
                          ) : (
                            <IconButton
                              size="small"
                              onMouseLeave={() => {
                                setHoverIcon2(false);
                              }}
                              onClick={() =>
                                onMediaSelection(
                                  getMediaFail(prop.file.id, files)
                                )
                              }
                            >
                              <RemoveRedEye fontSize="small" />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Dialog
                    open={dialog}
                    onClose={() => {
                      setDialog(false);
                    }}
                  >
                    <Box
                      component="span"
                      sx={{
                        p: 2,
                        border: "1px dashed grey",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <a href={path} target="_blank" rel="noopener noreferrer">
                        <img src={path} alt="" width={200} />
                      </a>
                    </Box>
                  </Dialog>
                </Grid>
              );
            })
          : null}
      </Grid>
    </PerfectScrollbar>
  );
}

export default OnboardingData;
