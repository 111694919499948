// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};
export const FRONTEND_DATE_FORMAT = "LLL";
// export const BACKEND_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const PICKER_DATE_FORMAT = "YYYY-MM-DDTHH:mm";
