import React, { useState, useEffect, useCallback } from "react";
import { TextField, Box, Button } from "@mui/material";
import axios from "../../../../../utils/axios";
import { getTabDetails } from "../MeetingHelpers";
import LoadingButton from "@mui/lab/LoadingButton";

const Summary = ({ meetingId }) => {
  const [summaryInput, setSummaryInput] = useState("");
  const [summeryData, setSummeryData] = useState({});
  const [hasExistingData, setHasExistingData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchSummary = useCallback(async () => {
    const getSummary = await getTabDetails("summery", meetingId);
    if (getSummary.length) {
      const summaryValue = getSummary[0];
      setSummaryInput(summaryValue.value);
      setSummeryData(summaryValue);
      setHasExistingData(!!summaryValue);
    } else {
      setSummaryInput("");
      setSummeryData({});
      setHasExistingData(false);
    }
  }, [meetingId]);

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary]);

  const handleSave = async () => {
    const data = {
      meeting_id: meetingId,
      value: summaryInput,
      key: "summery",
    };

    setIsLoading(true);
    setIsError(false);

    try {
      const response = await axios.post("/past-meeting-logs/create/", data);
      if (response.data.status) {
        fetchSummary();
        setSummaryInput(response.data.data.value);
        setHasExistingData(true);
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
    }

    setIsLoading(false);
  };

  const handleUpdate = async () => {
    const data = {
      key: "summery",
      value: summaryInput,
    };

    setIsLoading(true);
    setIsError(false);

    try {
      const response = await axios.patch(
        `/past-meeting-logs/update/${summeryData.id}/`,
        data
      );
      if (response.data.status) {
        fetchSummary();
        setSummaryInput(response.data.data.value);
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
    }

    setIsLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <TextField
        variant="filled"
        placeholder="Summary"
        fullWidth
        multiline
        rows={10}
        value={summaryInput}
        onChange={(event) => {
          setSummaryInput(event.target.value);
        }}
      />

      <Box sx={{ textAlign: "right" }}>
        {isLoading || isError ? (
          <LoadingButton loading variant="contained">
            {hasExistingData ? `Update` : `Save`}
          </LoadingButton>
        ) : (
          <Button
            variant="contained"
            onClick={hasExistingData ? handleUpdate : handleSave}
            disabled={summaryInput.length === 0}
          >
            {hasExistingData ? "Update" : "Save"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Summary;
