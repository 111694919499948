import React from "react";
import {
  Avatar,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

const DoctorInfo = ({ doctor }) => {
  return (
    <ListItem>
      <ListItemIcon>
        <Avatar
          alt={doctor.name}
          src="https://material-ui.com/static/images/avatar/2.jpg"
        />
      </ListItemIcon>
      <ListItemText
        primary={doctor.name}
        secondary={
          <>
            <Typography component="span" variant="body2" color={"gray"}>
              {doctor.speciality?.speciality_name ?? "No Speciality"}
            </Typography>
            <br />
            <Typography component="span" variant="body2" color={"gray"}>
              {doctor.address_line_1}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
};

export default DoctorInfo;
