import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import DashboardAnalytics from "./Analytics/DashboardAnalytics";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Dashboard() {
  return (
    <React.Fragment>
      <Helmet title="Analytics Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Analytics Dashboard
          </Typography>
        </Grid>
      </Grid>
      <Divider my={6} />
      <DashboardAnalytics />
    </React.Fragment>
  );
}

export default Dashboard;
