import React, { useCallback, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import DoctorDetails from "./DoctorDetails";
// import BulkUploadDoctors from "./BulkUploadDoctors";
import AddDoctorDialog from "./AddDoctorDialog";
import axios from "../../../utils/axios";
import { Alert, CircularProgress } from "@mui/material";
import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { spacing } from "@mui/system";
import { Snackbar } from "@mui/material";
import { get } from "lodash";
import ConfirmationDialog from "../../components/common/ConfirmationDialog";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "name", alignment: "left", label: "Name" },
  { id: "email", alignment: "left", label: "Email" },
  { id: "mobile_phone", alignment: "left", label: "Phone No." },
  { id: "speciality", alignment: "left", label: "Speciality" },
  { id: "address", alignment: "left", label: "Address" },
  { id: "actions", alignment: "center", label: "Actions" },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = () => {
  return (
    <Toolbar>
      <ToolbarTitle>
        <Typography variant="h6" id="tableTitle" p={2}>
          Doctors
        </Typography>
      </ToolbarTitle>
      <Spacer />
    </Toolbar>
  );
};

function DoctorsTable({
  rows,
  onDoctorSelection,
  onDelete,
  handleConfirmation,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.mobile_phone}</TableCell>
                      <TableCell align="left">
                        {row.speciality?.speciality_name}
                      </TableCell>
                      <TableCell align="left">{row.address_line_1}</TableCell>
                      <TableCell padding="none" align="center">
                        <Box sx={{ display: "inline" }}>
                          <IconButton
                            onClick={() => onDoctorSelection(row)}
                            aria-label="details"
                            size="large"
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleConfirmation(row)}
                            aria-label="delete"
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function DoctorsList() {
  const [dialog, setDialog] = useState(false);
  // const [bulkDialog, setBulkDialog] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  const [isEDDialogOpen, setIsEDDialogOpen] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [rowData, setRowData] = useState(null);

  const edDialogOpenHandler = () => {
    setIsEDDialogOpen((prev) => !prev);
    setSelectedDoctor(null);
  };

  const getDoctors = useCallback(async () => {
    let doctorsData = [];
    try {
      setIsLoading(true);
      const customersAPIResponse = await axios.get("/doctor/list");
      if (customersAPIResponse.status && customersAPIResponse.data) {
        setIsLoading(false);
        doctorsData = customersAPIResponse.data?.data;
      }
    } catch (e) {
      setIsLoading(false);
      doctorsData = [];
    }
    return doctorsData;
  }, []);

  const getSpeciality = useCallback(async () => {
    let specialityData = [];
    try {
      const specialityAPIResponse = await axios.get("/speciality/list");
      if (specialityAPIResponse.status && specialityAPIResponse.data) {
        specialityData = specialityAPIResponse.data?.data;
      }
    } catch (e) {
      specialityData = [];
    }
    return specialityData;
  }, []);

  useEffect(() => {
    const getDoctorsData = async () => {
      const doctorsData = await getDoctors();
      setDoctors(doctorsData);
    };
    getDoctorsData();
  }, [getDoctors]);

  useEffect(() => {
    const getSpecialityData = async () => {
      const specialityData = await getSpeciality();
      setSpeciality(specialityData);
    };
    getSpecialityData();
  }, [getSpeciality]);

  const handleDoctorUpdate = async (fullFetch = false) => {
    const doctorsData = await getDoctors();
    setDoctors(doctorsData);

    if (!selectedDoctor) {
      return;
    }

    let updatedDoctor = null;
    if (fullFetch) {
      const updatedDoctor = await fetchDoctorData(selectedDoctor.id);
      setSelectedDoctor(updatedDoctor);
    } else {
      updatedDoctor = doctorsData.find((e) => e.id === selectedDoctor.id);
      if (updatedDoctor) {
        setSelectedDoctor(updatedDoctor);
      }
    }
  };

  const handleConfirmation = (rowData) => {
    setRowData(rowData);
    setConfirmation((prev) => !prev);
  };

  const handleOnDelete = async (row) => {
    try {
      const deleteRespose = await axios.delete(`/doctor/delete/${row.id}`);
      if (deleteRespose.data.status === true) {
        const doctorsData = await getDoctors();
        setDoctors(doctorsData);
        setDeleteAlert(true);
        setDeleteStatus(true);
      } else {
        setDeleteAlert(true);
        setDeleteStatus(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const dialogHandler = () => {
    setDialog(!dialog);
  };
  // const bulkDialogHandler = () => {
  //   setBulkDialog(!bulkDialog);
  // };

  const handleDoctorCreation = async (doctor) => {
    const doctorsData = await getDoctors();
    setDoctors(doctorsData);
    setDialog(false);
    let newDoctor = null;
    if (doctor) {
      newDoctor = doctorsData.find((e) => e.id === doctor.id);
      if (newDoctor) {
        setSelectedDoctor(newDoctor);
      } else {
        setSelectedDoctor(doctor);
      }
    }

    setIsEDDialogOpen(true);
  };

  const fetchDoctorData = async (doctorId) => {
    const customerAPIResponse = await axios.get(`/doctor/get/${doctorId}`);
    const doctorData = get(customerAPIResponse, "data.data", null);
    return doctorData;
  };

  const handleDoctorSelection = async (doctor) => {
    try {
      setIsEDDialogOpen(true);
      const doctorData = await fetchDoctorData(doctor.id);
      setSelectedDoctor(doctorData);
    } catch (e) {
      // show error message
      setIsEDDialogOpen(false);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Doctors" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Doctors
          </Typography>
        </Grid>
        <Grid item>
          <div>
            {/* <Button
              onClick={bulkDialogHandler}
              sx={{ m: 1 }}
              variant="contained"
              color="primary"
            >
              <AddIcon />
              Bulk Upload
            </Button> */}
            <Button
              onClick={dialogHandler}
              sx={{ m: 1 }}
              variant="contained"
              color="primary"
            >
              <AddIcon />
              Add Doctor
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {isLoading ? (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: 400 }}
            >
              <CircularProgress m={2} color="secondary" />
            </Grid>
          ) : (
            <DoctorsTable
              rows={doctors}
              onDoctorSelection={handleDoctorSelection}
              handleConfirmation={handleConfirmation}
              onDelete={handleOnDelete}
            />
          )}
          <Snackbar
            open={deleteAlert}
            autoHideDuration={6000}
            onClose={() => setDeleteAlert(false)}
          >
            {deleteStatus ? (
              <Alert severity="success" sx={{ width: "100%" }}>
                Deleted successfully!
              </Alert>
            ) : (
              <Alert severity="error" sx={{ width: "100%" }}>
                Something went wrong!
              </Alert>
            )}
          </Snackbar>
        </Grid>
        <AddDoctorDialog
          open={dialog}
          setOpen={setDialog}
          onClose={dialogHandler}
          onDoctorCreate={handleDoctorCreation}
          speciality={speciality}
        />
        {/* <BulkUploadDoctors
          open={bulkDialog}
          onClose={bulkDialogHandler}
          onCustomerCreate={handleCustomerUpdate}
        /> */}
        {selectedDoctor ? (
          <DoctorDetails
            open={isEDDialogOpen}
            setOpen={setIsEDDialogOpen}
            onClose={edDialogOpenHandler}
            doctor={selectedDoctor}
            speciality={speciality}
            onDoctorUpdate={handleDoctorUpdate}
          />
        ) : null}
        {confirmation && (
          <ConfirmationDialog
            onClose={handleConfirmation}
            open={confirmation}
            setOpen={setConfirmation}
            data={rowData}
            deleteHandler={handleOnDelete}
            message={`Are you sure you want to delete ${rowData.name}`}
          />
        )}
      </Grid>
    </React.Fragment>
  );
}

export default DoctorsList;
