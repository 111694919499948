import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import logo from "../../../vendor/logo.png";
import axios from "../../../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setBrandings } from "../../../redux/slices/MasterSlice";

const BrandingSetting = () => {
  const brandings = useSelector((state) => state.Master.brandings);
  const globalLoader = useSelector((state) => state.Master.globalLoader);
  const logoUploaderRef = useRef(null);
  const watermarkUploaderRef = useRef(null);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [uploadedWatermark, setUploadedWatermark] = useState(null);
  const [loader, setLoader] = useState({ type: null, state: null });
  const [showAlert, setShowAlert] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [apiMessage, setApiMessage] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (brandings) {
      setUploadedLogo(brandings.logo_url);
      setUploadedWatermark(brandings.watermark_url);
    }
  }, [brandings]);

  const handleEditClick = (type) => {
    if (type === "logo") {
      if (logoUploaderRef.current) {
        logoUploaderRef.current.click();
      }
    } else {
      if (watermarkUploaderRef.current) {
        watermarkUploaderRef.current.click();
      }
    }
  };

  const handleSnackbar = () => {
    setShowAlert(false);
  };

  const uploadFile = useCallback(
    async (file, media_type) => {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("media_type", media_type);
      try {
        setLoader({ type: media_type, state: true });
        const { data } = await axios.post(
          "/api/admin/update-branding",
          formData
        );
        if (data.status) {
          setShowAlert(true);
          setShowStatus(true);
          if (data.data) {
            if (data.data.type === "LOGO") {
              setUploadedLogo(data.data.file_path);
              dispatch(
                setBrandings({ ...brandings, logo_url: data.data.file_path })
              );
            } else {
              setUploadedWatermark(data.data.file_path);
              dispatch(
                setBrandings({
                  ...brandings,
                  watermark_url: data.data.file_path,
                })
              );
            }
          }
          setApiMessage(data.message);
        } else {
          setShowAlert(true);
          setShowStatus(false);
          setApiMessage(data.message);
        }
      } catch (e) {
        console.log("error", e);
        setApiMessage(e);
      } finally {
        setLoader({ type: null, state: null });
      }
    },
    [brandings, dispatch]
  );

  return (
    <React.Fragment>
      <Helmet title="Appointments" />
      <Grid
        container
        component={Paper}
        elevation={3}
        sx={{ gap: 5 }}
        p={10}
        my={5}
      >
        <Typography variant="h6">Branding</Typography>
        <Divider sx={{ width: "100%" }} />
        <Typography variant="body2" color={"gray"}>
          Logos
        </Typography>
        {globalLoader ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: 400 }}
          >
            <CircularProgress m={2} color="secondary" />
          </Grid>
        ) : (
          <Grid
            container
            direction={"column"}
            justifyContent="space-between"
            alignItems={"flex-start"}
            sx={{ border: "1px solid #E0E0E0", gap: 5 }}
            p={5}
          >
            <Typography variant="h6">Main Logo</Typography>
            <Grid
              container
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              spacing={5}
            >
              <Grid item xs={12} md={10}>
                <Typography variant="body2" color={"gray"}>
                  Upload the logo for your practice. This logo will appear in
                  the waiting room Integrations for patients, and other
                  communications.
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  ref={logoUploaderRef}
                  style={{ display: "none" }}
                  onChange={(e) => uploadFile(e.target.files[0], "LOGO")}
                />
                <Button
                  variant="contained"
                  onClick={() => handleEditClick("logo")}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
            <Box
              sx={{
                height: 80,
                width: { xs: 130, md: 200 },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loader?.type === "LOGO" && loader?.state ? (
                <CircularProgress size={20} />
              ) : (
                <Box
                  component={"img"}
                  src={uploadedLogo ? uploadedLogo : logo}
                  alt="black-logo"
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
            </Box>

            <Divider sx={{ width: "100%" }} />
            <Typography variant="h6">Watermark</Typography>
            <Grid
              container
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              spacing={5}
            >
              <Grid item xs={12} md={10}>
                <Typography variant="body2" color={"gray"}>
                  Upload the watermark logo for your practice. This logo will
                  appear in telehealth sessions. We recommend a grey-scale
                  version of your main practice logo.
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  ref={watermarkUploaderRef}
                  style={{ display: "none" }}
                  onChange={(e) => uploadFile(e.target.files[0], "WATERMARK")}
                />
                <Button
                  variant="contained"
                  onClick={() => handleEditClick("watermark")}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
            <Box
              sx={{
                height: 80,
                width: { xs: 130, md: 200 },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loader?.type === "WATERMARK" && loader?.state ? (
                <CircularProgress size={20} />
              ) : (
                <Box
                  component={"img"}
                  src={uploadedWatermark ? uploadedWatermark : logo}
                  alt="black-logo"
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
            </Box>
          </Grid>
        )}
      </Grid>
      <Snackbar
        open={showAlert}
        autoHideDuration={3000}
        onClose={handleSnackbar}
      >
        {showStatus ? (
          <Alert severity="success" sx={{ width: "100%" }}>
            {apiMessage}
          </Alert>
        ) : (
          <Alert severity="error" sx={{ width: "100%" }}>
            {apiMessage}
          </Alert>
        )}
      </Snackbar>
    </React.Fragment>
  );
};

export default BrandingSetting;
