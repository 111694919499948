import { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  // Tab,
} from "@mui/material";
import DoctorInfo from "./DoctorInfo";
import PerfectScrollbar from "react-perfect-scrollbar";
import CloseIcon from "@mui/icons-material/Close";
import UpdateDoctorForm from "./UpdateDoctorForm";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
// import MeetingsList from "../Meetings/Meetings";

export default function DoctorDetails(props) {
  const { open, setOpen, onClose, doctor, speciality, onDoctorUpdate } = props;
  // const [openedTab, setOpenedTab] = useState("1");
  const [edit, setEdit] = useState(null);

  useEffect(() => {
    if (open === false) {
      setEdit(false);
    }
  }, [open]);

  // const handleChange = (event, newValue) => {
  //   setOpenedTab(newValue);
  // };

  const handleClose = () => {
    setOpen(false);
    // setOpenedTab("1");
  };
  const handleUpdate = async () => {
    await onDoctorUpdate();
    setEdit(false);
  };

  return (
    <PerfectScrollbar>
      <Dialog
        PaperProps={{ sx: { height: 700 } }}
        onClose={onClose}
        open={open}
        fullWidth
        maxWidth="md"
      >
        {/* <TabContext value={openedTab}> */}
        <DialogTitle>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={10}>
                {/* <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Basic Details" value="1" />
                    <Tab label="Appointments" value="2" />
                  </TabList> */}
              </Grid>
              <Grid item xs={2}>
                <CloseIcon
                  onClick={handleClose}
                  style={{
                    float: "right",
                    marginTop: 1,
                    marginBottom: 8,
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", typography: "body1" }}>
            {/* {edit ? (
                <UpdateCustomerForm
                  customer={customer}
                  onCustomerUpdate={handleUpdate}
                  onClick={() => setEdit(false)}
                />
              ) : (
                <DoctorInfo
                  customer={customer}
                  onClick={() => setEdit(true)}
                />
              )} */}
            {/* <TabPanel value="1"> */}
            {edit ? (
              <UpdateDoctorForm
                doctor={doctor}
                speciality={speciality}
                onDoctorUpdate={handleUpdate}
                onClick={() => setEdit(false)}
              />
            ) : (
              <DoctorInfo doctor={doctor} onClick={() => setEdit(true)} />
            )}
            {/* </TabPanel> */}
            {/* <TabPanel value="2">
                <MeetingsList customerTabData={customer} />
              </TabPanel>
              <TabPanel value="3">
                <OnboardingData customer={customer} />
              </TabPanel>
              <TabPanel value="4">
                <CustomerLogs customer={customer} />
              </TabPanel> */}
          </Box>
        </DialogContent>
        {/* </TabContext> */}
      </Dialog>
    </PerfectScrollbar>
  );
}
