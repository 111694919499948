import React from "react";
import { Grid, Paper, Typography, Button } from "@mui/material";

function DoctorInfo(props) {
  const { doctor, onClick } = props;
  return (
    <Paper elevation={16}>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Typography variant="h3" display="inline">
            Doctor's Information
          </Typography>
        </Grid>
        <Grid item sx={{ mr: 4 }}>
          <Button size="small" variant="outlined" onClick={onClick}>
            Edit
          </Button>
        </Grid>
      </Grid>
      <Paper sx={{ mr: 4, ml: 4 }}>
        <Grid
          container
          direction="row"
          justifyContent="stretch"
          alignItems="flex-start"
          spacing={3}
          sx={{ mt: 10 }}
        >
          <Grid item xs={3}>
            <h4 style={{ marginTop: 3 }}>Name :</h4>
          </Grid>
          <Grid item xs={3}>
            <Typography>{doctor.name}</Typography>
          </Grid>

          <Grid item xs={3}>
            <h4 style={{ marginTop: 3 }}>Email :</h4>
          </Grid>
          <Grid item xs={3}>
            <Typography>{doctor.email}</Typography>
          </Grid>

          <Grid item xs={3}>
            <h4 style={{ marginTop: 3 }}>Phone No :</h4>
          </Grid>
          <Grid item xs={3}>
            <Typography>{doctor.mobile_phone}</Typography>
          </Grid>

          <Grid item xs={3}>
            <h4 style={{ marginTop: 3 }}>Address :</h4>
          </Grid>
          <Grid item xs={3}>
            <Typography>{doctor.address_line_1}</Typography>
          </Grid>

          <Grid item xs={3}>
            <h4 style={{ marginTop: 3 }}>Speciality :</h4>
          </Grid>
          <Grid item xs={3}>
            <Typography>{doctor.speciality?.speciality_name}</Typography>
          </Grid>

          <Grid item xs={3}>
            <h4 style={{ marginTop: 3 }}>Gender :</h4>
          </Grid>
          <Grid item xs={3}>
            <Typography>{doctor.gender ? "Male" : "Female"}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
}
export default DoctorInfo;
