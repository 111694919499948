import React, { useState } from "react";
import {
  Box,
  // Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import FilterListIcon from "@mui/icons-material/FilterList";
import MeetingDetails from "./MeetingDetails";
import NavSection from "./NavSection";
import Header from "./Header";
import MeetingTabs from "./MeetingTabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import HistoryDetails from "./HistoryDetails";
import PerfectScrollbar from "react-perfect-scrollbar";

const MeetingPage = ({
  selectedAppointment,
  setSelectedAppointment,
  tabValue,
  setTabValue,
  meetings,
  isLoading,
  handleJoinMeeting,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check for mobile view
  const [mobileAction, setMobileAction] = useState(isMobile);
  const [filterValue, setFilterValue] = useState("");
  const [historyTab, setHistoryTab] = useState("appointment");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setSelectedAppointment(null);
  };

  const handleActionRemove = () => {
    setMobileAction((prev) => !prev);
    setSelectedAppointment(null);
  };

  const handleHistoryTab = (event, newValue) => {
    setHistoryTab(newValue);
  };

  return (
    <Paper elevation={2} sx={{ height: "70vh", overflow: "hidden" }}>
      <TabContext value={tabValue}>
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: { xs: mobileAction ? "none" : "block", sm: "block" },
              borderRight: "1px solid #e0e0e0",
            }}
          >
            <Stack
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              spacing={3}
              p={5}
            >
              <Typography variant="h6">Appointments</Typography>
              <MeetingTabs value={tabValue} handleChange={handleChange} />
              {/* <Button variant="text" startIcon={<FilterListIcon />}>
                FILTERS
              </Button> */}
              <TextField
                fullWidth
                placeholder="Search..."
                variant="outlined"
                size="small"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              />
            </Stack>
            <div style={{ height: "calc(63vh - 170px)", overflow: "auto" }}>
              <PerfectScrollbar>
                {isLoading ? (
                  <Box
                    display="flex"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress size={30} m={2} color="secondary" />
                  </Box>
                ) : (
                  <NavSection
                    filterValue={filterValue}
                    meetings={meetings}
                    selectedAppointment={selectedAppointment}
                    setSelectedAppointment={setSelectedAppointment}
                    tabValue={tabValue}
                    setMobileAction={setMobileAction}
                  />
                )}
              </PerfectScrollbar>
            </div>
          </Grid>
          <Grid
            item
            sx={{
              display: { xs: mobileAction ? "block" : "none", sm: "block" },
            }}
            xs={12}
            sm={8}
          >
            <Header
              removeAction={handleActionRemove}
              selectedAppointment={selectedAppointment}
            />
            {selectedAppointment && <Divider />}
            <div style={{ height: "calc(63vh - 88px)", overflow: "auto" }}>
              <PerfectScrollbar>
                <TabPanel value="today">
                  {selectedAppointment ? (
                    <MeetingDetails
                      selectedAppointment={selectedAppointment}
                      handleJoinMeeting={handleJoinMeeting}
                    />
                  ) : (
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      mt={40}
                    >
                      <Typography variant="h6">
                        No Appointment Selected
                      </Typography>
                    </Stack>
                  )}
                </TabPanel>
                <TabPanel value="upcoming">
                  {selectedAppointment ? (
                    <MeetingDetails
                      selectedAppointment={selectedAppointment}
                      handleJoinMeeting={handleJoinMeeting}
                    />
                  ) : (
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      mt={40}
                    >
                      <Typography variant="h6">
                        No Appointment Selected
                      </Typography>
                    </Stack>
                  )}
                </TabPanel>
                <TabPanel value="history">
                  {selectedAppointment ? (
                    <HistoryDetails
                      selectedAppointment={selectedAppointment}
                      historyTab={historyTab}
                      handleChange={handleHistoryTab}
                    />
                  ) : (
                    <Stack
                      direction={"row"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      mt={40}
                    >
                      <Typography variant="h6">
                        No Appointment Selected
                      </Typography>
                    </Stack>
                  )}
                </TabPanel>
              </PerfectScrollbar>
            </div>
          </Grid>
        </Grid>
      </TabContext>
    </Paper>
  );
};

export default MeetingPage;
