import React, { useState, useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Box,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "../../../utils/axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { get } from "lodash";
import AddAdminDialog from "./AddAdminDialog";

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "name", alignment: "left", label: "Admin Name" },
  { id: "email", alignment: "left", label: "Email" },
  { id: "actions", alignment: "center", label: "Actions" },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = () => {
  return (
    <Toolbar>
      <ToolbarTitle>
        <Typography variant="h6" id="tableTitle">
          Admin(s)
        </Typography>
      </ToolbarTitle>
      <Spacer />
    </Toolbar>
  );
};

function AdminTable({ rows, onEditAdmin, onDelete }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell padding="none" align="center">
                        <Box sx={{ display: "inline" }}>
                          <IconButton
                            onClick={() => onEditAdmin(row)}
                            aria-label="edit"
                            size="large"
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() => onDelete(row)}
                            aria-label="delete"
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function CompanyInfo(props) {
  const [admins, setAdmins] = useState([]);
  const [deleteStatus, setDeleteStatus] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { company } = props;

  const [dialog, setDialog] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [adminData, setAdminData] = useState(null);

  const getAdmins = useCallback(async () => {
    let adminsData = [];
    try {
      setIsLoading(true);
      const adminsAPIResponse = await axios.get(`/admin/company/${company.id}`);
      setIsLoading(false);
      adminsData = adminsAPIResponse.data.data;
    } catch (e) {
      setIsLoading(false);
      adminsData = [];
    }
    return adminsData;
  }, [company]);

  useEffect(() => {
    const getAdminsData = async () => {
      const adminsData = await getAdmins();
      setAdmins(adminsData);
    };
    getAdminsData();
  }, [getAdmins]);

  const handleAdminUpdate = async (fullFetch = false) => {
    const adminsData = await getAdmins();
    setAdmins(adminsData);
    setDialog(false);

    if (!selectedAdmin) {
      return;
    }

    let updatedAdmin = null;
    if (fullFetch) {
      const updatedAdmin = await fetchAdminData(selectedAdmin.id);
      setSelectedAdmin(updatedAdmin);
    } else {
      updatedAdmin = adminsData.find((e) => e.id === selectedAdmin.id);
      if (updatedAdmin) {
        setSelectedAdmin(updatedAdmin);
      }
    }
  };

  const handleOnDelete = async (row) => {
    try {
      const deleteRespose = await axios.delete(
        `/admin/company/${company.id}/admin/${row.id}`
      );
      if (deleteRespose.data.status === true) {
        const adminsData = await getAdmins();
        setAdmins(adminsData);
        setDeleteAlert(true);
        setDeleteStatus(true);
      } else {
        setDeleteAlert(true);
        setDeleteStatus(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const dialogHandler = () => {
    setDialog(!dialog);
    setAdminData(null);
  };

  const handleAdminCreation = async (admin) => {
    const adminsData = await getAdmins();
    setAdmins(adminsData);
    setDialog(false);
    let newAdmin = null;
    if (admin) {
      newAdmin = adminsData.find((e) => e.id === admin.id);
      if (newAdmin) {
        setSelectedAdmin(newAdmin);
      } else {
        setSelectedAdmin(admin);
      }
    }
  };

  const fetchAdminData = async (adminId) => {
    const adminAPIResponse = await axios.get(
      `/admin/company/${company.id}/admin/${adminId}`
    );
    const adminApiData = get(adminAPIResponse, "data.data", null);
    return adminApiData;
  };

  const handleEditAdmin = async (admin) => {
    try {
      const adminDataApi = await fetchAdminData(admin.id);
      setAdminData(adminDataApi);
      setDialog(true);
    } catch (e) {
      // show error message
      setDialog(false);
    }
  };

  return (
    <PerfectScrollbar>
      <Paper elevation={16} sx={{ minHeight: 500 }}>
        {isLoading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: 400 }}
          >
            <CircularProgress m={2} color="secondary" />
          </Grid>
        ) : (
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h3" display="inline">
                {company.name}
              </Typography>
            </Grid>
            <Grid item sx={{ mr: 4 }}>
              <Button size="small" variant="contained" onClick={dialogHandler}>
                Add Admin
              </Button>
            </Grid>

            <Grid item xs={12}>
              <AdminTable
                rows={admins}
                onDelete={handleOnDelete}
                onEditAdmin={handleEditAdmin}
              />

              <Snackbar
                open={deleteAlert}
                autoHideDuration={6000}
                onClose={() => setDeleteAlert(false)}
              >
                {deleteStatus ? (
                  <Alert severity="success" sx={{ width: "100%" }}>
                    Deleted successfully!
                  </Alert>
                ) : (
                  <Alert severity="error" sx={{ width: "100%" }}>
                    Something went wrong!
                  </Alert>
                )}
              </Snackbar>
            </Grid>
          </Grid>
        )}
        <AddAdminDialog
          open={dialog}
          setOpen={setDialog}
          onClose={dialogHandler}
          onAdminCreate={handleAdminCreation}
          company={company}
          admin={adminData}
          onAdminUpdate={handleAdminUpdate}
        />
      </Paper>
    </PerfectScrollbar>
  );
}
export default CompanyInfo;
