import React, { useState } from "react";
import { useCallback, useEffect } from "react";
import {
  Box,
  Dialog,
  FormControl,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "../../../utils/axios";
import moment from "moment";
import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import LogTable from "./LogTable";
import { get, orderBy } from "lodash";

function CustomerLogs({ customer }) {
  const [dialog, setDialog] = useState(false);
  const [customerLogs, setCustomerLogs] = useState([]);
  const [path, setPath] = useState("");
  const endDate = moment();
  const startDate = moment();
  startDate.subtract(1, "week");
  const [fromDate, setFromDate] = useState(startDate.format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(endDate.format("YYYY-MM-DD"));
  const [lastPage, setLastPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [page, setPage] = useState(1);
  const [type, setType] = useState("all");
  const [mediaType, setMediaType] = useState("");

  const getLogs = useCallback(async () => {
    let customerLogs = [];
    try {
      const query = {
        start_date: fromDate,
        end_date: toDate,
        page: page,
        page_size: 4,
        type: type,
      };
      const logsApiResponse = await axios.get(
        `/admin/customer_verification_logs/${customer?.id}`,
        { params: query }
      );
      setLastPage(get(logsApiResponse, "data.last_page", 0));
      setTotalPages(get(logsApiResponse, "data.total", 0));
      customerLogs = logsApiResponse.data.data;
      if (customerLogs.length) {
        customerLogs = orderBy(
          customerLogs,
          function (o) {
            return new moment(o.created_at).unix();
          },
          ["desc"]
        );
      }
    } catch (e) {
      customerLogs = [];
    }
    return customerLogs;
  }, [customer.id, fromDate, toDate, page, type]);

  useEffect(() => {
    const getLogsData = async () => {
      const customerLogs = await getLogs();
      setCustomerLogs(customerLogs);
    };
    getLogsData();
  }, [getLogs]);

  const onMediaSelection = (path, type) => {
    setDialog(true);
    setMediaType(type);
    setPath(path);
  };

  function typeHandler(input) {
    const input_type = input.target.value;
    setType(input_type);
  }

  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{ my: 2 }}
      >
        <Grid item xs={3} sx={{ marginTop: 2 }}>
          <Typography variant="h3">View Logs</Typography>
        </Grid>

        <Grid item xs={3} sx={{ marginTop: 2 }}>
          {/* <Box sx={{ minWidth: 120 }}> */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="Select Type"
              onChange={typeHandler}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"SELFIE_VERIFICATION"}>Selfie</MenuItem>
              <MenuItem value={"AUDIO_VERIFICATION"}>Audio</MenuItem>
            </Select>
          </FormControl>
          {/* </Box> */}
        </Grid>

        <Grid item xs={6}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            sx={{ my: 2 }}
          >
            <Grid item xs={4}>
              <DatePicker
                views={["year", "month", "day"]}
                label="From"
                disableMaskedInput
                maxDate={Date.now()}
                inputFormat="yyyy-MM-dd"
                value={fromDate}
                onChange={(newValue) => {
                  if (newValue) {
                    newValue = moment(newValue).format("YYYY-MM-DD");
                    setFromDate(newValue);
                  } else {
                    setFromDate(null);
                  }
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                views={["year", "month", "day"]}
                label="To"
                disableMaskedInput
                maxDate={Date.now()}
                inputFormat="yyyy-MM-dd"
                value={toDate}
                onChange={(newValue) => {
                  if (newValue) {
                    newValue = moment(newValue).format("YYYY-MM-DD");
                    setToDate(newValue);
                  } else {
                    setToDate(null);
                  }
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <LogTable
        customerLogs={customerLogs}
        onMediaSelection={onMediaSelection}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        lastPage={lastPage}
      />
      <Dialog
        open={dialog}
        onClose={() => {
          setDialog(false);
        }}
      >
        {mediaType === "SELFIE_VERIFICATION" ||
        mediaType === "INCODE_ONBORDING" ? (
          <Box
            component="span"
            sx={{
              p: 2,
              border: "1px dashed grey",
              display: "flex",
            }}
          >
            <a href={path} target="_blank" rel="noopener noreferrer">
              <img src={path} alt="" width={200} />
            </a>
          </Box>
        ) : (
          <Box sx={{ m: 2 }}>
            <audio src={path} controls></audio>
          </Box>
        )}
      </Dialog>
    </Box>
  );
}
export default CustomerLogs;
