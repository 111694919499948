import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function DeleteConfirmation(props) {
  const { open, setOpen, onDelete, deleteRow } = props;
  const handleCancel = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    onDelete(deleteRow);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>{"Delete Confirmation"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this company?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>No</Button>
        <Button onClick={handleDelete} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
