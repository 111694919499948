// import { ReactComponent as DoctorIcon } from "../../assets/svg/doctor.svg";
// import { ReactComponent as UsersIcon } from "../../assets/svg/patient.svg";
import { ReactComponent as SlidersIcon } from "../../assets/svg/dashboard.svg";
import { ReactComponent as CalendarIcon } from "../../assets/svg/appointment.svg";
// import { ReactComponent as SettingIcon } from "../../assets/svg/setting.svg";

const pagesSection = [
  {
    href: "/dashboard",
    icon: SlidersIcon,
    title: "Dashboard",
  },
  // {
  //   href: "/employees",
  //   icon: UsersIcon,
  //   title: "Employees",
  // },
  // {
  //   href: "/customers",
  //   icon: UsersIcon,
  //   title: "Patients",
  // },
  {
    href: "/appointments",
    icon: CalendarIcon,
    title: "Appointments",
  },
  // {
  //   href: "/doctors",
  //   icon: DoctorIcon,
  //   title: "Doctors",
  // },
  // {
  //   href: "/settings",
  //   icon: SettingIcon,
  //   title: "Settings",
  //   children: [
  //     {
  //       href: "/setting/branding",
  //       title: "Branding",
  //     },
  //     {
  //       href: "/setting/notification",
  //       title: "Notification",
  //     },
  //   ],
  // },
];

const navItems = [
  {
    pages: pagesSection,
  },
];

export default navItems;
