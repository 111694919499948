import React from "react";
import {
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PatientInfo from "./PatientInfo";
import DoctorInfo from "./DoctorInfo";

const MeetingDetails = ({
  selectedAppointment,
  handleJoinMeeting,
  tempDisabled,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check for mobile view
  const isLarge = useMediaQuery(theme.breakpoints.up("xl")); // Check for large view

  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      spacing={3}
      p={5}
    >
      {!tempDisabled && (
        <Grid item xs={12}>
          <Grid
            container
            flexDirection={isMobile ? "column" : "row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              backgroundColor: "#DFEBFF",
              p: isMobile ? "10px" : "10px 30px",
              borderRadius: 1,
            }}
            rowSpacing={isMobile ? 5 : 0}
          >
            <Grid item>
              <Typography>CareNow Session in progress</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<LoginIcon />}
                onClick={handleJoinMeeting}
              >
                Join
              </Button>
            </Grid>
            <Grid item>
              <Stack
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={1}
              >
                <AccessTimeIcon />
                <Typography>{selectedAppointment?.startTime}</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid
          container
          spacing={5}
          direction={isMobile ? "column-reverse" : "row"}
        >
          <Grid item sm={6}>
            {selectedAppointment?.patient && (
              <PatientInfo
                patient={selectedAppointment.patient}
                selectedAppointment={selectedAppointment}
              />
            )}
          </Grid>
          <Grid item sm={6}>
            {selectedAppointment?.doctor && (
              <DoctorInfo doctor={selectedAppointment.doctor} />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Stack direction={"column"} spacing={isLarge ? 5 : 2}>
          <Typography variant="h6">Reason For Visit</Typography>
          <Typography
            variant="body2"
            color={"gray"}
            style={{ overflowWrap: "break-word" }}
          >
            {selectedAppointment?.description}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default MeetingDetails;
