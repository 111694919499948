import React, { useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import { get } from "lodash";
import MuiPhoneNumber from "material-ui-phone-number";
import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import dayjs from "dayjs";
import { spacing } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import axios from "../../../utils/axios";
import moment from "moment";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function CustomerForm({ customer, onCustomerCreate, onCustomerUpdate }) {
  let validationSchema = null;
  const [countryCode, setCountryCode] = useState("us");
  const validationObject = {
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    date_of_birth: Yup.string().nullable().required("Required"),
    gender: Yup.string().required("Required"),
    mobile_phone: Yup.string().required("Required"),
  };
  if (customer) {
    validationSchema = Yup.object().shape(validationObject);
  } else {
    validationObject.password = Yup.string()
      .min(8, "Must be at least 8 characters")
      .max(255)
      .required("Required");
    validationSchema = Yup.object().shape(validationObject);
  }
  const updateCustomer = async (param) => {
    const { values, setErrors, setStatus, setSubmitting } = param;
    values.mobile_phone = values.mobile_phone?.replace(/\+/g, "");
    values.date_of_birth = moment(values.date_of_birth).format("yyyy-MM-DD");
    try {
      const response = await axios.put(
        `/admin/customers/${customer.id}`,
        values
      );
      const responseData = response.data;
      if (get(responseData, "status", false) === true) {
        setStatus({ sent: true });
        setSubmitting(false);
        onCustomerUpdate();
      } else {
        setStatus({ sent: false });
        const apiErrors = get(responseData, "message.messages.errors", []);
        if (apiErrors.length === 0) {
          apiErrors.push({ field: "Update", message: "Operation failed." });
        }
        setErrors({
          submit: apiErrors.map((e) => `[${e.field}] ${e.message}`),
        });
        setSubmitting(false);
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  const createCustomer = async (param) => {
    const { values, resetForm, setErrors, setStatus, setSubmitting } = param;
    values.mobile_phone = values.mobile_phone?.replace(/\+/g, "");
    values.date_of_birth = moment(values.date_of_birth).format("yyyy-MM-DD");
    try {
      const response = await axios.post("/admin/customers", values);
      const responseData = response.data;
      if (get(responseData, "status", false) === true) {
        resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
        onCustomerCreate(responseData.data);
      } else {
        setStatus({ sent: false });
        setErrors({
          submit: get(responseData, "message.messages.errors", []).map(
            (e) => `[${e.field}] ${e.message}`
          ),
        });
        setSubmitting(false);
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };
  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    if (customer) {
      updateCustomer({
        values,
        resetForm,
        setErrors,
        setStatus,
        setSubmitting,
      });
    } else {
      createCustomer({
        values,
        resetForm,
        setErrors,
        setStatus,
        setSubmitting,
      });
    }
  };
  const initialValues = {
    first_name: get(customer, "first_name", ""),
    last_name: get(customer, "last_name", ""),
    email: get(customer, "email", ""),
    date_of_birth: get(customer, "date_of_birth", new Date().toISOString()),
    gender: get(customer, "gender", 1),
    mobile_phone: get(customer, "mobile_phone", ""),
    address_line_1: get(customer, "address_line_1", ""),
    account_number: get(customer, "account_number", ""),
    service: get(customer, "service", ""),
    password: get(customer, "password", ""),
  };

  const getCountryCode = () => {
    if (!customer) {
      try {
        axios.get("http://ip-api.com/json").then(function (payload) {
          setCountryCode(payload?.data?.countryCode);
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  getCountryCode();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
        status,
      }) => (
        <>
          {status && status.sent && (
            <Alert severity="success" my={3}>
              Your data has been submitted successfully!
            </Alert>
          )}
          {status &&
            status.sent === false &&
            get(errors, "submit", []).map((e) => (
              <Alert key={e} severity="error" my={3}>
                {e}
              </Alert>
            ))}

          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <TextField
                    name="first_name"
                    label="First Name*"
                    value={values.first_name === null ? "" : values.first_name}
                    error={Boolean(touched.first_name && errors.first_name)}
                    fullWidth
                    helperText={touched.first_name && errors.first_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="last_name"
                    label="Last Name*"
                    value={values.last_name === null ? "" : values.last_name}
                    error={Boolean(touched.last_name && errors.last_name)}
                    fullWidth
                    helperText={touched.last_name && errors.last_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <TextField
                    name="email"
                    label="Email*"
                    value={values.email === null ? "" : values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiPhoneNumber
                    defaultCountry={countryCode.toLowerCase()}
                    name="mobile_phone"
                    label="Phone No*"
                    value={
                      values.mobile_phone === null ? "" : values.mobile_phone
                    }
                    error={Boolean(touched.mobile_phone && errors.mobile_phone)}
                    fullWidth
                    helperText={touched.mobile_phone && errors.mobile_phone}
                    onBlur={handleBlur}
                    onChange={(e) =>
                      setFieldValue(
                        "mobile_phone",
                        "+" + e.replace(/[^A-Z0-9]()+/gi, "")
                      )
                    }
                    variant="outlined"
                    sx={{ mt: 2 }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disableFuture
                      label="Date of Birth"
                      value={dayjs(values.date_of_birth)}
                      onChange={(e) => {
                        setFieldValue(
                          "date_of_birth",
                          new Date(e).toISOString()
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          name="date_of_birth"
                          error={Boolean(
                            touched.date_of_birth && errors.date_of_birth
                          )}
                          helperText={
                            touched.date_of_birth && errors.date_of_birth
                          }
                          onBlur={handleBlur}
                          fullWidth
                          {...params}
                          variant="outlined"
                          my={2}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="Gender">Gender</InputLabel>
                    <Select
                      labelid="Gender"
                      id="Gender"
                      name="gender"
                      value={values.gender}
                      label="Gender"
                      onChange={handleChange}
                      error={Boolean(touched.gender && errors.gender)}
                      fullWidth
                      // helperText={touched.gender && errors.gender}
                    >
                      <MenuItem key={1} value={1}>
                        Male
                      </MenuItem>
                      <MenuItem key={0} value={0}>
                        Female
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <TextField
                    name="address_line_1"
                    label="Address"
                    value={
                      values.address_line_1 === null
                        ? ""
                        : values.address_line_1
                    }
                    error={Boolean(
                      touched.address_line_1 && errors.address_line_1
                    )}
                    fullWidth
                    helperText={touched.address_line_1 && errors.address_line_1}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="account_number"
                    label="Account Number"
                    value={
                      values.account_number === null
                        ? ""
                        : values.account_number
                    }
                    error={Boolean(
                      touched.account_number && errors.account_number
                    )}
                    fullWidth
                    helperText={touched.account_number && errors.account_number}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6}>
                <Grid item xs={6}>
                  <TextField
                    name="service"
                    label="Service"
                    value={values.service === null ? "" : values.service}
                    error={Boolean(touched.service && errors.service)}
                    fullWidth
                    helperText={touched.service && errors.service}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
                {customer ? null : (
                  <Grid item xs={6}>
                    <TextField
                      name="password"
                      label="Password*"
                      value={values.password || ""}
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  mt={3}
                >
                  {customer ? "Update" : "Save"}
                </Button>
              </Grid>
            </form>
          )}
        </>
      )}
    </Formik>
  );
}

export default CustomerForm;
