import * as React from "react";
import { Box, Dialog, Grid, DialogContent, DialogTitle } from "@mui/material";
import CompanyInfo from "./CompanyInfo";
import PerfectScrollbar from "react-perfect-scrollbar";
import CloseIcon from "@mui/icons-material/Close";

export default function EmployeeDetails(props) {
  const { open, setOpen, onClose, company } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <PerfectScrollbar>
      <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
        <DialogTitle>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={10} />
              <Grid item xs={2}>
                <CloseIcon
                  onClick={handleClose}
                  style={{
                    float: "right",
                    marginTop: 1,
                    marginBottom: 8,
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <CompanyInfo company={company} />
          </Box>
        </DialogContent>
      </Dialog>
    </PerfectScrollbar>
  );
}
