import React, { useCallback, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import CompanyDetails from "./CompanyDetails";
import AddCompanyDialog from "./AddCompanyDialog";
import axios from "../../../utils/axios";
import { Alert, CircularProgress } from "@mui/material";
import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  RemoveRedEye as RemoveRedEyeIcon,
  Edit,
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { spacing } from "@mui/system";
import { Snackbar } from "@mui/material";
import { get } from "lodash";
import DeleteConfirmation from "./DeleteConfirmation";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "name", alignment: "left", label: "Company Name" },
  { id: "admin", alignment: "center", label: "Admin(s)" },
  { id: "actions", alignment: "center", label: "Actions" },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = () => {
  return (
    <Toolbar>
      <ToolbarTitle>
        <Typography variant="h6" id="tableTitle">
          Companies
        </Typography>
      </ToolbarTitle>
      <Spacer />
    </Toolbar>
  );
};

function CompanyTable({ rows, onEditCompany, onCompanySelection, onDelete }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell padding="none" align="center">
                        <Box sx={{ display: "inline" }}>
                          <IconButton
                            onClick={() => onCompanySelection(row)}
                            aria-label="details"
                            size="large"
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell padding="none" align="center">
                        <Box sx={{ display: "inline" }}>
                          <IconButton
                            onClick={() => onEditCompany(row)}
                            aria-label="edit"
                            size="large"
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() => onDelete(row)}
                            aria-label="delete"
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function Companies() {
  const [dialog, setDialog] = useState(false);
  const [companies, setCompanys] = useState([]);
  const [isEDDialogOpen, setIsEDDialogOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);

  const edDialogOpenHandler = () => {
    setIsEDDialogOpen(!isEDDialogOpen);
  };

  const getCompanies = useCallback(async (company) => {
    let companiesData = [];
    try {
      if (!company) {
        setIsLoading(true);
      }
      const companiesAPIResponse = await axios.get("/admin/companies");
      setIsLoading(false);
      companiesData = companiesAPIResponse.data.data;
    } catch (e) {
      setIsLoading(false);
      companiesData = [];
    }
    return companiesData;
  }, []);

  useEffect(() => {
    const getCompaniesData = async () => {
      const companiesData = await getCompanies();
      setCompanys(companiesData);
    };
    getCompaniesData();
  }, [getCompanies]);

  const handleCompanyUpdate = async (fullFetch = false) => {
    const companiesData = await getCompanies();
    setCompanys(companiesData);
    setDialog(false);

    if (!selectedCompany) {
      return;
    }

    let updatedCompany = null;
    if (fullFetch) {
      const updatedCompany = await fetchCompanyData(selectedCompany.id);
      setSelectedCompany(updatedCompany);
    } else {
      updatedCompany = companiesData.find((e) => e.id === selectedCompany.id);
      if (updatedCompany) {
        setSelectedCompany(updatedCompany);
      }
    }
  };

  const handleOnDelete = async (row) => {
    try {
      const deleteRespose = await axios.delete(`/admin/companies/${row.id}`);
      if (deleteRespose.data.status === true) {
        const companiesData = await getCompanies();
        setCompanys(companiesData);
        setDeleteAlert(true);
        setDeleteStatus(true);
      } else {
        setDeleteAlert(true);
        setDeleteStatus(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const dialogHandler = () => {
    setDialog(!dialog);
    setCompanyData(null);
  };

  const handleCompanyCreation = async (company) => {
    const companiesData = await getCompanies(company);
    setCompanys(companiesData);
    setDialog(false);

    let newCompany = null;
    if (company) {
      newCompany = companiesData.find((e) => e.id === company.id);
      if (newCompany) {
        setSelectedCompany(newCompany);
      } else {
        setSelectedCompany(company);
      }
    }

    setIsEDDialogOpen(true);
  };

  const fetchCompanyData = async (companyId) => {
    const companyAPIResponse = await axios.get(`/admin/companies/${companyId}`);
    const companyData = get(companyAPIResponse, "data.data", null);
    return companyData;
  };

  const handleCompanySelection = async (company) => {
    try {
      const companyData = await fetchCompanyData(company.id);
      setSelectedCompany(companyData);
      setIsEDDialogOpen(true);
    } catch (e) {
      // show error message
      setIsEDDialogOpen(false);
    }
  };
  const handleEditCompany = async (company) => {
    try {
      const companyDataResponse = await fetchCompanyData(company.id);
      setCompanyData(companyDataResponse);
      setDialog(true);
    } catch (e) {
      // show error message
      setDialog(false);
    }
  };
  const handleDeleteConfirmation = (row) => {
    setDeleteConfirmation(true);
    setDeleteRow(row);
  };

  return (
    <React.Fragment>
      <Helmet title="Companies" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Super Admin
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Button
              onClick={dialogHandler}
              sx={{ m: 1 }}
              variant="contained"
              color="primary"
            >
              <AddIcon />
              Add Company
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {isLoading ? (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: 400 }}
            >
              <CircularProgress m={2} color="secondary" />
            </Grid>
          ) : (
            <CompanyTable
              rows={companies}
              onCompanySelection={handleCompanySelection}
              onDelete={handleDeleteConfirmation}
              onEditCompany={handleEditCompany}
            />
          )}
          <Snackbar
            open={deleteAlert}
            autoHideDuration={6000}
            onClose={() => setDeleteAlert(false)}
          >
            {deleteStatus ? (
              <Alert severity="success" sx={{ width: "100%" }}>
                Deleted successfully!
              </Alert>
            ) : (
              <Alert severity="error" sx={{ width: "100%" }}>
                Something went wrong!
              </Alert>
            )}
          </Snackbar>
        </Grid>
        <AddCompanyDialog
          open={dialog}
          setOpen={setDialog}
          onClose={dialogHandler}
          onCompanyCreate={handleCompanyCreation}
          company={companyData}
          onCompanyUpdate={handleCompanyUpdate}
        />
        <CompanyDetails
          open={isEDDialogOpen}
          setOpen={setIsEDDialogOpen}
          onClose={edDialogOpenHandler}
          company={selectedCompany}
          onAdminUpdate={handleCompanyUpdate}
        />
        <DeleteConfirmation
          open={deleteConfirmation}
          deleteRow={deleteRow}
          setOpen={setDeleteConfirmation}
          onDelete={handleOnDelete}
        />
      </Grid>
    </React.Fragment>
  );
}

export default Companies;
