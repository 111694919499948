import React from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import AppointmentInfo from "./AppointmentInfo";
import moment from "moment";

const PatientInfo = ({ patient, selectedAppointment }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check for mobile view
  const isLarge = useMediaQuery(theme.breakpoints.up("xl")); // Check for large view

  return (
    <Stack direction={"column"} spacing={isLarge ? 5 : 2}>
      <Typography variant="h6">Patient Information</Typography>
      <Stack direction={"row"} spacing={isLarge ? 5 : 2}>
        <Typography
          variant="body2"
          color={"gray"}
          sx={{ width: isMobile ? 140 : 120 }}
        >
          First Name:
        </Typography>
        <Typography variant="body2" color={"gray"}>
          {patient.first_name}
        </Typography>
      </Stack>
      <Stack direction={"row"} spacing={isLarge ? 5 : 2}>
        <Typography
          variant="body2"
          color={"gray"}
          sx={{ width: isMobile ? 140 : 120 }}
        >
          Last Name:
        </Typography>
        <Typography variant="body2" color={"gray"}>
          {patient.last_name}
        </Typography>
      </Stack>
      <Stack direction={"row"} spacing={isLarge ? 5 : 2}>
        <Typography
          variant="body2"
          color={"gray"}
          sx={{ width: isMobile ? 140 : 120 }}
        >
          Preferred Name:
        </Typography>
        <Typography variant="body2" color={"gray"}>
          {patient.name}
        </Typography>
      </Stack>

      <Stack direction={"row"} spacing={isLarge ? 5 : 2}>
        <Typography
          variant="body2"
          color={"gray"}
          sx={{ width: isMobile ? 140 : 120 }}
        >
          Email:
        </Typography>
        <Typography variant="body2" color={"gray"}>
          {patient.email}
        </Typography>
      </Stack>
      <Stack direction={"row"} spacing={isLarge ? 5 : 2}>
        <Typography
          variant="body2"
          color={"gray"}
          sx={{ width: isMobile ? 140 : 120 }}
        >
          DOB:
        </Typography>
        <Typography variant="body2" color={"gray"}>
          {moment(patient.date_of_birth).format("MM/DD/YYYY")}
        </Typography>
      </Stack>
      <AppointmentInfo selectedAppointment={selectedAppointment} />
    </Stack>
  );
};

export default PatientInfo;
