import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { Paper } from "@mui/material";
import axios from "../../../../../utils/axios";
import { getTabDetails } from "../MeetingHelpers";
import NotesModal from "./NotesModal";

const Notes = ({ meetingId }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedNoteId(null);
    setNoteInput("");
    setSelectedNote(null);
    setIsError(false);
  };

  const [noteInput, setNoteInput] = useState("");
  const [notesData, setNotesData] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedNoteId, setSelectedNoteId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchNotes = useCallback(async () => {
    const getNotes = await getTabDetails("notes", meetingId);
    setNotesData(getNotes);
  }, [meetingId]);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  const handleSave = async () => {
    const data = {
      meeting_id: meetingId,
      value: noteInput,
      key: "notes",
    };

    setIsLoading(true);
    setIsError(false);

    try {
      const response = await axios.post("/past-meeting-logs/create/", data);
      if (response.data.status) {
        fetchNotes();
        setNoteInput("");
        handleClose();
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
    }

    setIsLoading(false);
  };

  const isNoteTextEmpty = () => {
    return noteInput.trim().length < 3;
  };

  const handleUpdate = (note, id) => {
    setSelectedNote(note);
    setSelectedNoteId(id);
    setNoteInput(note.value);
    handleOpen();
  };

  const handleUpdateNote = async () => {
    if (!selectedNote) return;

    const updatedNote = {
      key: "notes",
      value: noteInput,
    };

    setIsLoading(true);
    setIsError(false);

    try {
      const response = await axios.patch(
        `/past-meeting-logs/update/${selectedNoteId}/`,
        updatedNote
      );

      if (response.data.status) {
        fetchNotes();
        setNoteInput("");
        setSelectedNote(null);
        setSelectedNoteId(null);
        handleClose();
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
    }

    setIsLoading(false);
  };

  return (
    <Box>
      <Box sx={{ textAlign: "right" }}>
        <Button
          onClick={() => {
            setSelectedNote(null);
            setNoteInput("");
            handleOpen();
          }}
          variant="contained"
          startIcon={<AddIcon />}
        >
          Add new note
        </Button>

        <NotesModal
          open={open}
          handleClose={handleClose}
          noteInput={noteInput}
          isLoading={isLoading}
          isError={isError}
          selectedNote={selectedNote}
          handleSave={handleSave}
          handleUpdateNote={handleUpdateNote}
          setNoteInput={setNoteInput}
          isNoteTextEmpty={isNoteTextEmpty}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          margin: "30px 0",
        }}
      >
        {notesData.length === 0 ? (
          <Paper elevation={3} sx={{ bgcolor: "#f1f1f1a9" }}>
            <Typography variant="body1" p={4}>
              No data found
            </Typography>
          </Paper>
        ) : (
          notesData.map((note) => (
            <Paper
              key={note.id}
              elevation={3}
              sx={{
                bgcolor: "#f1f1f1a9",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="body1"
                p={4}
                sx={{ width: "85%", wordWrap: "break-word" }}
              >
                {note.value}
              </Typography>
              <Button onClick={() => handleUpdate(note, note.id)}>
                Update
              </Button>
            </Paper>
          ))
        )}
      </Box>
    </Box>
  );
};

export default Notes;
