import React from "react";
import { Grid, Paper, Typography, Button } from "@mui/material";

function EmployeeInfo(props) {
  const { customer, onClick } = props;
  return (
    <Paper elevation={16}>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Typography variant="h3" display="inline">
            Patient's Information
          </Typography>
        </Grid>
        <Grid item sx={{ mr: 4 }}>
          <Button size="small" variant="outlined" onClick={onClick}>
            Edit
          </Button>
        </Grid>
      </Grid>
      <Paper sx={{ mr: 4, ml: 4 }}>
        <Grid
          container
          direction="row"
          justifyContent="stretch"
          alignItems="flex-start"
          spacing={3}
          sx={{ mt: 10 }}
        >
          <Grid item xs={3}>
            <h4 style={{ marginTop: 3 }}>First Name :</h4>
          </Grid>
          <Grid item xs={3}>
            <Typography>{customer.first_name}</Typography>
          </Grid>
          <Grid item xs={3}>
            <h4 style={{ marginTop: 3 }}>Last Name :</h4>
          </Grid>
          <Grid item xs={3}>
            <Typography>{customer.last_name}</Typography>
          </Grid>
          <Grid item xs={3}>
            <h4 style={{ marginTop: 3 }}>Email :</h4>
          </Grid>
          <Grid item xs={3}>
            <Typography>{customer.email}</Typography>
          </Grid>

          <Grid item xs={3}>
            <h4 style={{ marginTop: 3 }}>Phone No :</h4>
          </Grid>
          <Grid item xs={3}>
            <Typography>{customer.mobile_phone}</Typography>
          </Grid>

          <Grid item xs={3}>
            <h4 style={{ marginTop: 3 }}>Address :</h4>
          </Grid>
          <Grid item xs={3}>
            <Typography>{customer.address_line_1}</Typography>
          </Grid>
          <Grid item xs={3}>
            <h4 style={{ marginTop: 3 }}>Gender :</h4>
          </Grid>
          <Grid item xs={3}>
            <Typography>{customer.gender === 0 ? "Female" : "Male"}</Typography>
          </Grid>
          <Grid item xs={3}>
            <h4 style={{ marginTop: 3 }}>Date of Birth :</h4>
          </Grid>
          <Grid item xs={3}>
            <Typography>{customer.date_of_birth.split("T")[0]}</Typography>
          </Grid>
          <Grid item xs={3}>
            <h4 style={{ marginTop: 3 }}>Account Number :</h4>
          </Grid>
          <Grid item xs={3}>
            <Typography>{customer.account_number}</Typography>
          </Grid>

          <Grid item xs={3}>
            <h4 style={{ marginTop: 3 }}>Service :</h4>
          </Grid>
          <Grid item xs={3}>
            <Typography>{customer.service}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
}
export default EmployeeInfo;
