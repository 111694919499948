import React from "react";
import {
  Avatar,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const NavSection = ({
  tabValue,
  filterValue,
  meetings,
  selectedAppointment,
  setSelectedAppointment,
  setMobileAction,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check for mobile view
  const filteredArray = meetings.filter((meeting) =>
    meeting.patient?.name.toLowerCase().includes(filterValue.toLowerCase())
  );

  return (
    <List>
      {filteredArray.length ? (
        filteredArray.map((meeting, index) => (
          <ListItemButton
            key={index}
            selected={selectedAppointment?.id === meeting.id}
            onClick={() => {
              setSelectedAppointment(meeting);
              if (isMobile) setMobileAction(true);
            }}
          >
            <ListItemIcon>
              <Avatar
                alt="Remy Sharp"
                src="https://material-ui.com/static/images/avatar/2.jpg"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Grid
                  container
                  flexDirection={"row"}
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  columnSpacing={2}
                >
                  <Grid item>
                    <Typography
                      component="span"
                      variant="h6"
                      whiteSpace={"nowrap"}
                      sx={{ fontSize: 12 }}
                    >
                      {meeting.patient?.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      component="span"
                      variant="body2"
                      color={"gray"}
                      sx={{ fontSize: 10 }}
                    >
                      {meeting.doctor?.name}
                    </Typography>
                  </Grid>
                </Grid>
              }
              secondary={meeting.startDate}
            />
          </ListItemButton>
        ))
      ) : (
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          mt={15}
        >
          {tabValue === "history" ? (
            <Typography variant="body2">No History</Typography>
          ) : (
            <Typography variant="body2">{`No ${
              tabValue.charAt(0).toUpperCase() + tabValue.slice(1)
            } Appointments`}</Typography>
          )}
        </Stack>
      )}
    </List>
  );
};

export default NavSection;
