import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmationDialog = (props) => {
  const { onClose, open, setOpen, data, deleteHandler, message } = props;
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    deleteHandler(data);
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Confirmation{" "}
        <span style={{ float: "right", cursor: "pointer" }}>
          <CloseIcon onClick={handleClose} />
        </span>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>{message}?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm}>Yes</Button>
        <Button onClick={onClose}>No</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
