import React, { useState } from "react";
import {
  Box,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  Typography,
  Link,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../../utils/axios";
import { appConfig } from "../../../config";
import { get } from "lodash";

export default function BulkUploadCustomers(props) {
  const { open, onClose, onCustomerCreate } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAlert, setShowAlert] = useState(null);
  const [showStatus, setShowStatus] = useState(null);
  const [apiMessage, setApiMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("file", selectedFile, selectedFile.name);
    try {
      const bulkUploadResponse = await axios.post(
        "/admin/customers/import",
        formData
      );
      setIsSubmitting(false);
      const bulkUploadData = bulkUploadResponse.data;
      const apiResponseMessage = get(
        bulkUploadData,
        "message",
        "Error occured while uploading file. Please download the updated template!"
      );
      if (bulkUploadData.status === true) {
        onCustomerCreate();
        setShowAlert(true);
        setShowStatus(true);
        setApiMessage(apiResponseMessage);
      } else {
        setShowAlert(true);
        setShowStatus(false);
        setApiMessage(apiResponseMessage);
      }
    } catch (error) {
      setShowAlert(true);
      setShowStatus(false);
      setApiMessage(error.message);
    }
  };
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleClose = () => {
    onClose();
    setSelectedFile(null);
  };

  const handleSnackbar = () => {
    setShowAlert(false);
    if (showStatus) onClose();
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={10}>
                <Typography variant="h4">Upload Patients Data</Typography>
              </Grid>
              <Grid item xs={2}>
                <CloseIcon
                  onClick={handleClose}
                  style={{
                    float: "right",
                    marginTop: 1,
                    marginBottom: 8,
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <Typography>
              Please make sure that you are going to upload filled template
              file. If not then please download the template file first.{" "}
              <Link
                href={`${appConfig.API_URL}/public/template_customer.csv`}
                underline="none"
                target="_blank"
                rel="noreferrer"
              >
                (DOWNLOAD TEMPLATE)
              </Link>
            </Typography>
            <Typography variant="h4" sx={{ mt: 10 }}>
              Select filled template file(csv) to upload Patients
            </Typography>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 10, pb: 2 }}
            >
              <input
                type="file"
                id="bulkUpload"
                name="bulkUpload"
                onChange={changeHandler}
              />

              <Button
                sx={{ mt: 7 }}
                type="submit"
                size="small"
                variant="outlined"
                onClick={handleSubmit}
                disabled={selectedFile === null || isSubmitting}
              >
                Upload
              </Button>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={showAlert}
        autoHideDuration={3000}
        onClose={handleSnackbar}
      >
        {showStatus ? (
          <Alert severity="success" sx={{ width: "100%" }}>
            {apiMessage}
          </Alert>
        ) : (
          <Alert severity="error" sx={{ width: "100%" }}>
            {apiMessage}
          </Alert>
        )}
      </Snackbar>
    </>
  );
}
