import React, { useState } from "react";
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import dashboardItems from "../components/sidebar/dashboardItems";
import superAdmindashboardItems from "../components/sidebar/superAdminDashboardItems";
import doctorDashboardItems from "../components/sidebar/doctorDashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import useAuth from "../hooks/useAuth";
import { isSuperAdmin, isDoctor } from "../utils/helpers";
import axios from "../utils/axios";
import { useCallback } from "react";
import { useEffect } from "react";
import { setBrandings, setGlobalLoader } from "../redux/slices/MasterSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {
  const { user } = useAuth();
  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useDispatch();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getBranding = useCallback(async () => {
    try {
      dispatch(setGlobalLoader());
      const { data } = await axios.get("/api/admin/brandings");
      if (data.status) {
        dispatch(setBrandings(data.data));
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      dispatch(setGlobalLoader());
    }
  }, [dispatch]);

  useEffect(() => {
    getBranding();
  }, [getBranding]);

  const brandings = useSelector((state) => state.Master.brandings);

  // const theme = useTheme();
  // const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        {user ? (
          <Box sx={{ display: { xs: "block", lg: "none" } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              items={
                isSuperAdmin(user.userType)
                  ? superAdmindashboardItems
                  : isDoctor(user.userType)
                  ? doctorDashboardItems
                  : dashboardItems
              }
            />
          </Box>
        ) : null}
        {user ? (
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Sidebar
              PaperProps={{ style: { width: drawerWidth } }}
              items={
                isSuperAdmin(user.userType)
                  ? superAdmindashboardItems
                  : isDoctor(user.userType)
                  ? doctorDashboardItems
                  : dashboardItems
              }
              logo={brandings ? brandings.logo_url : null}
            />
          </Box>
        ) : null}
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={5}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default Dashboard;
