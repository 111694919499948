import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Typography = styled(MuiTypography)(spacing);

const illustrationCardStyle = (props) => css`
  ${props.illustration && props.theme.palette.mode !== "dark"}
`;

const Card = styled(MuiCard)`
  position: relative;

  ${illustrationCardStyle}
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const IllustrationImage = styled.img`
  height: 60px;
  position: absolute;
  right: ${(props) => props.theme.spacing(3)};
  bottom: ${(props) => props.theme.spacing(3)};

  ${(props) => props.theme.breakpoints.between("xs", "lg")} {
    display: block;
  }

  @media (min-width: 1700px) {
    display: block;
  }
`;

const InCodeStats = ({
  title,
  amount,
  chip,
  illustration,
  handleIncodeClick,
}) => {
  return (
    <>
      <Card
        illustration={illustration}
        style={{
          height: "150px",
          cursor: "pointer",
        }}
        onClick={() => {
          handleIncodeClick();
        }}
      >
        <CardContent>
          <Typography variant="h6" mb={6} pr={12}>
            {title}
          </Typography>
          <Typography variant="h3" mb={6}>
            <Box fontWeight="fontWeightRegular">{amount}</Box>
          </Typography>
        </CardContent>

        {!!illustration && (
          <IllustrationImage src={illustration} alt="Illustration" />
        )}
      </Card>
    </>
  );
};

export default InCodeStats;
