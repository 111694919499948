import * as React from "react";
import {
  Box,
  Dialog,
  Grid,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomerForm from "./CustomerForm";
import { Helmet } from "react-helmet-async";

export default function AddCustomerDialog(props) {
  const { open, setOpen, onClose, onCustomerCreate } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
      <DialogTitle>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <CloseIcon
                onClick={handleClose}
                style={{
                  float: "right",
                  marginTop: 1,
                  marginBottom: 8,
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Helmet title="Add Patient" />
          <Typography variant="h3" gutterBottom display="inline">
            Add Patient's Information
          </Typography>
          <Grid sx={{ mt: 8 }}>
            <CustomerForm onCustomerCreate={onCustomerCreate} />
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
