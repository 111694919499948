import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import Stats from "./Stats";
import { get } from "lodash";
import InCodeStats from "./inCodeStat";
import IncodeDialog from "./incodeDialog";
import axios from "../../../utils/axios";
import moment from "moment";
import DatePicker from "@mui/lab/DatePicker";

const DashboardAnalytics = () => {
  const [analytics, setAnalytics] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [inCodeRows, setInCodeRows] = useState([]);
  const [dashboardFilter, setDashboardFilter] = useState("");
  const [customDate, setCustomDate] = useState(false);
  const endDate = moment();
  const startDate = moment();
  startDate.subtract(1, "week");
  const [fromDate, setFromDate] = useState(startDate.format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(endDate.format("YYYY-MM-DD"));

  const getAnalyticsData = useCallback(async () => {
    let query = {};
    if (dashboardFilter && dashboardFilter === "custom") {
      query = {
        filterType: dashboardFilter,
        start_date: fromDate,
        end_date: toDate,
      };
    } else if (dashboardFilter && dashboardFilter !== "custom") {
      query.filterType = dashboardFilter;
    }
    try {
      setIsLoading(true);
      const getAnalytics = await axios.get("/admin/dashboard/stats", {
        params: query,
      });
      setIsLoading(false);
      if (getAnalytics.data.status === true) {
        setAnalytics(getAnalytics.data.data);
      }
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  }, [dashboardFilter, fromDate, toDate]);

  const handleIncodeClick = async () => {
    setDialogOpen(true);
    try {
      const pendingIncodeAPiResponse = await axios.get(
        "/admin/dashboard/stats/pendingsessions"
      );
      const incodeApiData = pendingIncodeAPiResponse.data;
      if (get(incodeApiData, "status", false) === true) {
        setInCodeRows(incodeApiData.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDashboardFilter = (e) => {
    if (e.target.value === "custom") {
      setCustomDate(true);
      setDashboardFilter(e.target.value);
    } else {
      setCustomDate(false);
      setDashboardFilter(e.target.value);
    }
  };

  useEffect(() => {
    getAnalyticsData();
  }, [getAnalyticsData]);
  return (
    <>
      {isLoading ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: 400 }}
        >
          <CircularProgress m={2} color="secondary" />
        </Grid>
      ) : (
        <>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            my={2}
            spacing={1}
          >
            <Grid item xs={6}>
              <Typography variant="h4">Patient stats</Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth size="small">
                <InputLabel id="filterStats">Filter</InputLabel>
                <Select
                  labelId="filterStats"
                  id="filterStats"
                  value={dashboardFilter}
                  label="Filter"
                  onChange={handleDashboardFilter}
                >
                  <MenuItem value={"yesterday"}>Yesterday</MenuItem>
                  <MenuItem value={"last_7_days"}>Last 7 days</MenuItem>
                  <MenuItem value={"last_30_days"}>Last 30 days</MenuItem>
                  <MenuItem value={"this_month"}>This month</MenuItem>
                  <MenuItem value={"last_month"}>Last month</MenuItem>
                  <MenuItem value={"custom"}>Custom Date Range</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {customDate ? (
              <Grid item xs={4}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={6}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      label="From"
                      disableMaskedInput
                      maxDate={Date.now()}
                      inputFormat="yyyy-MM-dd"
                      value={fromDate}
                      onChange={(newValue) => {
                        if (newValue) {
                          newValue = moment(newValue).format("YYYY-MM-DD");
                          setFromDate(newValue);
                        } else {
                          setFromDate(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      label="To"
                      disableMaskedInput
                      maxDate={Date.now()}
                      inputFormat="yyyy-MM-dd"
                      value={toDate}
                      onChange={(newValue) => {
                        if (newValue) {
                          newValue = moment(newValue).format("YYYY-MM-DD");
                          setToDate(newValue);
                        } else {
                          setToDate(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Grid container justifyContent="flex-start" spacing={5}>
            <Grid item xs={12} sm={4} md={3}>
              <Stats
                title="Total Patients"
                amount={get(analytics, "total_customer", 0)}
                illustration="/static/img/illustrations/employees.png"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Stats
                title="Completed onBoarding"
                amount={get(
                  analytics,
                  "total_customer_completed_onboarding",
                  0
                )}
                illustration="/static/img/illustrations/successful.png"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Stats
                title="Pending OnBoarding"
                amount={get(
                  analytics,
                  "total_customer_incomplete_onboarding",
                  0
                )}
                illustration="/static/img/illustrations/waiting.png"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <InCodeStats
                title="Incode pending session"
                amount={get(
                  analytics,
                  "total_pending_requests_manual_approval",
                  0
                )}
                illustration="/static/img/illustrations/voice2.png"
                handleIncodeClick={handleIncodeClick}
              />
            </Grid>
          </Grid>
          <Typography variant="h4" mt={6} mb={2}>
            Patient Authentication Stats
          </Typography>
          <Grid
            container
            justifyContent="flex-start"
            spacing={5}
            sx={{ mt: 1 }}
          >
            {/* <Grid item xs={12} sm={4} md={3}>
              <Stats
                title="Total Inbound Calls"
                amount={get(analytics, "total_customer_inbound_calls", 0)}
                illustration="/static/img/illustrations/inBoundCall.png"
              />
            </Grid> */}
            <Grid item xs={12} sm={4} md={3}>
              <Stats
                title="Fraud Attempts Prevented"
                amount={get(analytics, "total_fraud_attempts_prevented", 0)}
                illustration="/static/img/illustrations/working.png"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Stats
                title="Total Selfie Verifications"
                amount={get(analytics, "total_selfie_verifications", 0)}
                illustration="/static/img/illustrations/selfie.png"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Stats
                title="Successful Selfie Verifications"
                amount={get(analytics, "total_selfie_requests_success", 0)}
                illustration="/static/img/illustrations/successful.png"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Stats
                title="Failed Selfie Verifications"
                amount={get(analytics, "total_selfie_requests_failed", 0)}
                illustration="/static/img/illustrations/failed.png"
              />
            </Grid>
          </Grid>
          <IncodeDialog
            onClose={setDialogOpen}
            open={dialogOpen}
            inCodeRows={inCodeRows}
          />
        </>
      )}
    </>
  );
};

export default DashboardAnalytics;
