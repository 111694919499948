import React, { useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";
import { get } from "lodash";
import dayjs from "dayjs";
import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "../../../../utils/axios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import {
  // compareStartEndTime,
  formateCreateMeetingsData,
  errorMessageHandler,
} from "../../../../utils/helpers";
import Theme from "../../../../utils/theme";
import moment from "moment";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

function MeetingForm({
  meeting: meetingData,
  onMeetingCreate,
  onMeetingUpdate,
  doctors,
  speciality,
  patientData,
}) {
  const [selectedPatientData, setSelectedPatientData] = useState(null);
  const [checkDate, setCheckDate] = useState(true);

  const getHour = new Date().getHours();
  const getMinutes = new Date().getMinutes();

  const minTime = dayjs()
    .set("hour", getHour)
    .set("minute", getMinutes)
    .startOf("minute");

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    // first_name: Yup.string().required("Required"),
    // last_name: Yup.string().required("Required"),
    // mobile_phone: Yup.string().required("Required"),
    // email: Yup.string().required("Required"),
    speciality_id: Yup.number().required("Required"),
    doctor_id: Yup.number().required("Required"),
    patient_id: Yup.number().required("Required"),
    start_date: Yup.date().nullable().required("Required"),
    start_time: Yup.date().nullable().required("Required"),
    meeting_description: Yup.string().required("Required"),
  });

  const updateMeeting = async (param) => {
    const { values, setErrors, setStatus, setSubmitting } = param;
    // const formattedValues = {
    //   ...values,
    //   start_date: moment(new Date(values.start_date)).format("YYYY-MM-DD"),
    //   start_time: moment(new Date(values.start_time)).format("HH:mm:ss"),
    // };
    const formattedValues = {
      ...values,
      first_name: selectedPatientData?.first_name,
      last_name: selectedPatientData?.last_name,
      mobile_phone: selectedPatientData?.mobile_phone,
      email: selectedPatientData?.email,
    };
    try {
      const response = await axios.patch(
        `/ringcentral/update-meeting/${meetingData.bridge_id}`,
        formattedValues
      );
      const responseData = response.data;
      if (get(responseData, "status", false) === true) {
        setStatus({ sent: true });
        setSubmitting(false);
        onMeetingUpdate();
      } else {
        setStatus({ sent: false });
        const apiErrors = errorMessageHandler(responseData);
        if (apiErrors.length === 0) {
          apiErrors.push({ field: "Create", message: "Operation failed." });
        }
        setErrors({
          submit: apiErrors.map((e) =>
            e.field ? `[${e.field}] ${e.message}` : e.message
          ),
        });
        setSubmitting(false);
      }
    } catch (error) {
      console.log("error", error);
      setStatus({ sent: false });
      setErrors({ submit: ["Something went wrong!"] });
      setSubmitting(false);
    }
  };

  const createMeeting = async (param) => {
    const { values, resetForm, setErrors, setStatus, setSubmitting } = param;
    const formattedValues = {
      ...values,
      first_name: selectedPatientData?.first_name,
      last_name: selectedPatientData?.last_name,
      mobile_phone: selectedPatientData?.mobile_phone,
      email: selectedPatientData?.email,
      start_date: moment(new Date(values.start_date)).format(),
      start_time: moment(new Date(values.start_time)).format(),
    };

    try {
      const response = await axios.post(
        "/ringcentral/create-meeting/",
        formattedValues
      );
      const responseData = response.data;
      if (get(responseData, "status", false) === true) {
        resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
        onMeetingCreate(formateCreateMeetingsData(responseData.data));
      } else {
        setStatus({ sent: false });
        const apiErrors = errorMessageHandler(responseData);
        if (apiErrors.length === 0) {
          apiErrors.push({ field: "Create", message: "Operation failed." });
        }
        setErrors({
          submit: apiErrors.map((e) =>
            e.field ? `[${e.field}] ${e.message}` : e.message
          ),
        });
        setSubmitting(false);
      }
    } catch (error) {
      console.log("error", error);
      setStatus({ sent: false });
      setErrors({ submit: ["Something went wrong!"] });
      setSubmitting(false);
    }
  };
  const handleSubmit = (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    if (meetingData) {
      updateMeeting({
        values,
        resetForm,
        setErrors,
        setStatus,
        setSubmitting,
      });
    } else {
      createMeeting({
        values,
        resetForm,
        setErrors,
        setStatus,
        setSubmitting,
      });
    }
  };

  const initialValues = {
    name: get(meetingData, "name", ""),
    // first_name: get(meetingData, "first_name", ""),
    // last_name: get(meetingData, "last_name", ""),
    // mobile_phone: get(meetingData, "mobile_phone", ""),
    // email: get(meetingData, "email", ""),
    speciality_id: get(meetingData, "speciality_id", ""),
    doctor_id: get(meetingData, "doctor_id", ""),
    patient_id: get(meetingData, "patient_id", ""),
    start_date: get(meetingData, "start_date", new Date()),
    start_time: get(meetingData, "start_time", new Date()),
    meeting_description: get(meetingData, "meeting_description", ""),
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
        status,
      }) => (
        <>
          {status && status.sent && (
            <Alert severity="success" my={3}>
              Your data has been submitted successfully!
            </Alert>
          )}
          {status &&
            status.sent === false &&
            get(errors, "submit", []).map((e) => (
              <Alert key={e} severity="error" my={3}>
                {e}
              </Alert>
            ))}

          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={20}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label="Meeting Title*"
                    value={values.name === null ? "" : values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.patient_id && errors.patient_id)}
                  >
                    <InputLabel id="patient_id">Patient</InputLabel>
                    <Select
                      labelid="patient_id"
                      id="patient_id"
                      name="patient_id"
                      value={values.patient_id}
                      label="Patient"
                      onChange={(e) => {
                        handleChange(e);

                        const selectedValue = e.target.value;
                        const selectedPatient = patientData.find(
                          (item) => item.id === selectedValue
                        );

                        setSelectedPatientData(selectedPatient);
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      }}
                    >
                      {patientData.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {`${item.first_name} ${item.last_name}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormHelperText sx={{ pl: 3, color: Theme.COLORS.ERROR }}>
                    {touched.patient_id && errors.patient_id}
                  </FormHelperText>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name="first_name"
                    label="First Name*"
                    value={
                      selectedPatientData ? selectedPatientData.first_name : ""
                    }
                    fullWidth
                    disabled
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="last_name"
                    label="Last Name*"
                    value={
                      selectedPatientData ? selectedPatientData.last_name : ""
                    }
                    fullWidth
                    disabled
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="mobile_phone"
                    label="Phone*"
                    value={
                      selectedPatientData
                        ? selectedPatientData.mobile_phone
                        : ""
                    }
                    fullWidth
                    disabled
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="email"
                    label="Email*"
                    value={selectedPatientData ? selectedPatientData.email : ""}
                    fullWidth
                    disabled
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      touched.speciality_id && errors.speciality_id
                    )}
                  >
                    <InputLabel id="Speciality">Speciality</InputLabel>
                    <Select
                      labelid="Speciality"
                      id="Speciality"
                      name="speciality_id"
                      value={values.speciality_id}
                      label="Speciality"
                      onChange={(e) => {
                        setFieldValue("doctor_id", "");
                        handleChange(e);
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      }}
                    >
                      {speciality.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.speciality_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormHelperText sx={{ pl: 3, color: Theme.COLORS.ERROR }}>
                    {touched.speciality_id && errors.speciality_id}
                  </FormHelperText>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.doctor_id && errors.doctor_id)}
                  >
                    <InputLabel>Doctor</InputLabel>
                    <Select
                      labelid="Doctor"
                      id="Doctor"
                      name="doctor_id"
                      value={values.doctor_id}
                      label="Doctor"
                      onChange={handleChange}
                      disabled={!values.speciality_id}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      }}
                    >
                      {doctors ? (
                        doctors
                          .filter(
                            (doctor) =>
                              values?.speciality_id === doctor?.speciality_id
                          )
                          .map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))
                      ) : (
                        <MenuItem disabled>No doctor available</MenuItem>
                      )}
                      {/* {doctors &&
                      doctors?.filter(
                        (doctor) =>
                          values?.speciality_id === doctor?.speciality_id
                      ).length === 0 ? (
                        <MenuItem disabled>No doctor available</MenuItem>
                      ) : null} */}
                    </Select>
                  </FormControl>
                  <FormHelperText sx={{ pl: 3, color: Theme.COLORS.ERROR }}>
                    {touched.doctor_id && errors.doctor_id}
                  </FormHelperText>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disablePast
                      label="Date"
                      openTo="year"
                      views={["year", "month", "day"]}
                      value={values.start_date}
                      onChange={(e) => {
                        if (
                          new Date(e).toDateString() ===
                          new Date().toDateString()
                        ) {
                          setCheckDate(true);
                        } else {
                          setCheckDate(false);
                        }

                        setFieldValue("start_date", e);
                      }}
                      renderInput={(params) => (
                        <TextField
                          name="start_date"
                          error={Boolean(
                            touched.start_date && errors.start_date
                          )}
                          helperText={touched.start_date && errors.start_date}
                          onBlur={handleBlur}
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      label="Time"
                      value={values.start_time}
                      minTime={checkDate ? minTime : null}
                      onChange={(e) => setFieldValue("start_time", e)}
                      renderInput={(params) => (
                        <TextField
                          name="start_time"
                          error={Boolean(
                            touched.start_time && errors.start_time
                          )}
                          helperText={touched.start_time && errors.start_time}
                          onBlur={handleBlur}
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="meeting_description"
                    label="Reason for visit"
                    fullWidth
                    multiline
                    rows={3}
                    value={
                      values.meeting_description === null
                        ? ""
                        : values.meeting_description
                    }
                    error={Boolean(
                      touched.meeting_description && errors.meeting_description
                    )}
                    helperText={
                      touched.meeting_description && errors.meeting_description
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  mt={3}
                >
                  {meetingData ? "Update" : "Save"}
                </Button>
              </Grid>
            </form>
          )}
        </>
      )}
    </Formik>
  );
}

export default MeetingForm;
